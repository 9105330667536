import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL, token } from "../../../utils/constant";
import { Table } from "react-bootstrap";

export default function DashboardVendorDetails() {
  const [total, setTotal] = useState([]);
  const [verify, setVerify] = useState([]);
  const [pending, setPending] = useState([]);

  function getAllBookingHistory() {
    axios({
      method: "post",
      url: `${baseURL}users/getAllLength`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: { status: 2 },
    })
      .then((response) => {
        setTotal(response.data.total);
        setVerify(response.data.verify);
        setPending(response.data.pending);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getAllBookingHistory();
  }, []);

  return (
    <>
      <h5>Vendor Details</h5>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="p-3">Work</th>
            <th className="p-3">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-3">Work Shop Status</td>
            <td className="p-3">{total.toString() ? total : "-"}</td>
          </tr>
          <tr>
            <td className="p-3">Verify Status</td>
            <td className="p-3">{verify.toString() ? verify : "-"}</td>
          </tr>
          <tr>
            <td className="p-3">Pending Status</td>
            <td className="p-3">{pending.toString() ? pending : "-"}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
