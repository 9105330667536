import React from "react";
import { Card } from "react-bootstrap";
import { baseURL } from "../../utils/constant";
import NoDataFound from "../../utils/NoDataFound";

export default function SOSEmergencyTable(props) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive pt-3">
          <table
            className={`table table-bordered ${props.loader && "loading"}`}
          >
            <thead className="text-center">
              <tr>
                <th>No</th>
                <th scope="col">SOSTitle</th>
                <th scope="col">BasePrice</th>
                <th scope="col">Advance Price</th>
                <th scope="col">Incusion</th>
                <th scope="col">Icon</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.sosData && props.sosData.length > 0 ? (
                props.sosData?.map((SOSEmergencyData, index) => {
                  return (
                    <tr className="border-bottom" key={index}>
                      <td className="col-1"> {index + 1} </td>
                      <td className="col-2">{SOSEmergencyData?.sosTitle}</td>
                      <td className="col-1">{SOSEmergencyData?.basePrice}</td>
                      <td className="col-1">{SOSEmergencyData.advancePrice}</td>
                      <td className="col-2">{SOSEmergencyData?.incusion}</td>
                      <td className="col-2 text-center">
                        <Card.Img
                          style={{ width: "100px", height: "100px" }}
                          variant="top"
                          src={baseURL + SOSEmergencyData?.icon}
                          alt="img"
                        />
                      </td>
                      <td className="col-2 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() =>
                            props.editDiscount(SOSEmergencyData?._id)
                          }
                          style={{ margin: "0 15px", cursor: "pointer" }}
                          width="20"
                          height="20"
                          fill="#223385"
                          className="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fillRule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() =>
                            props.deleteSOSEmergencydata(SOSEmergencyData?._id)
                          }
                          style={{ cursor: "pointer" }}
                          width="20"
                          height="20"
                          fill="red"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path
                            fillRule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                          />
                        </svg>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
