import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import { baseURL, token } from "../utils/constant";

export default function Banner() {
  const [image, setImage] = useState("");
  const [data, setData] = useState([]);
  const [validated, setValidated] = useState(false);

  // all banner
  async function getAllData() {
    var myHeaders = new Headers();
    myHeaders.append("authorization", token);

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseURL}admin/getAllBanner`, requestOptions)
      .then((response) => response.json())
      .then((result) => setData(result.Data))
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    getAllData();
  }, []);

  // ---------------------------------------------------------------------------------

  // deleteBanner
  const deleteBanner = async (id) => {
    const selected = data?.filter((brand) => brand?._id === id);

    var myHeaders = new Headers();
    myHeaders.append("authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      bannerId: selected[0]?._id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    if (window.confirm("Delete?")) {
      await fetch(
        `${baseURL}admin/deleteBanner`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          getAllData();
        })
        .catch((error) => console.log("error", error));
    }
  };

  // ---------------------------------------------------------------------------------

  const profileImagetoBase64 = (e) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => resolve(setImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      var myHeaders = new Headers();
      myHeaders.append("authorization", token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        image: image,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${baseURL}admin/addNewBanner`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setImage("");
          getAllData();
        })
        .catch((error) => console.log("error", error));
    }

    setValidated(true);
  };

  return (
    <>
      <style jsx>
        {`
          .banner-image {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;
          }
          .banner-image:hover {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
          }
        `}
      </style>

      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="row">
            <div className="col-12 col-xl-10 mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Banner</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Form
            className="mb-5"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Row className="col-lg-12 m-2">
              <Form.Group
                className="col-lg-3 mb-2"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>
                  Image
                  <span className="ps-1 figure-caption">
                    ( H = 550px, W = 400px )
                  </span>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={profileImagetoBase64}
                  placeholder=""
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Iamge.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="align-self-md-end mb-2"
                as={Col}
                md="3"
                controlId="validationCustom05"
              >
                <Button
                  type="submit"
                  className="btn btn-primary"
                  style={{ padding: "0.575rem 0.75rem" }}
                >
                  Submit
                </Button>
              </Form.Group>
            </Row>
          </Form>

          <Row>
            {data.map((banner, index) => {
              return (
                <Col
                  key={index}
                  className="col-lg-3 col-md-6 col-12 border p-3 m-2"
                  style={{ width: "280px" }}
                >
                  <Card className="bg-body-secondary p-2">
                    <Card.Img
                      className="banner-image"
                      variant="top"
                      src={`${baseURL}${banner?.image}`}
                      alt="img"
                    />
                    <Card.Body>
                      <Button
                        variant="danger"
                        className="w-100"
                        onClick={() => deleteBanner(banner?._id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="white"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path
                            fillRule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                          />
                        </svg>
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
}
