import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL, token } from "../../../utils/constant";
import { Table } from "react-bootstrap";

export default function DashboardEarningDetails() {
  const [today, setToday] = useState([]);
  const [yesterday, setYesterday] = useState([]);
  const [week, setWeek] = useState([]);
  const [month, setMonth] = useState([]);

  function getAllBookingHistory() {
    axios({
      method: "post",
      url: `${baseURL}users/getTotalEarning`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: { status: 2 },
    })
      .then((response) => {
        setToday(response.data.totalToday);
        setYesterday(response.data.totalYesterday);
        setWeek(response.data.totalWeek);
        setMonth(response.data.totalMonthAmount);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getAllBookingHistory();
  }, []);

  return (
    <>
      <h5>Earning Details</h5>
      <Table striped bordered hover>
        <thead></thead>
        <tbody>
          <tr>
            <td className="p-3">Today</td>
            <td className="p-3">{today.toString() ? today : "-"}</td>
          </tr>
          <tr>
            <td className="p-3">Yesterday</td>
            <td className="p-3">{yesterday.toString() ? yesterday : "-"}</td>
          </tr>
          <tr>
            <td className="p-3">Week</td>
            <td className="p-3">{week.toString() ? week : "-"}</td>
          </tr>
          <tr>
            <td className="p-3">Monthly</td>
            <td className="p-3">{month.toString() ? month : "-"}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
