import React from "react";
import NoDataFound from "../../utils/NoDataFound";
import ViewUsers from "../Pages/ManageMembers/ViewUsers";

export default function ShowUsersTable(props) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive pt-3">
          <table
            className={`table table-bordered ${props.loader && "loading"}`}
          >
            <thead className="text-center">
              <tr>
                <th>Name</th>
                <th>Email </th>
                <th>Phone </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.data && props.data.length > 0 ? (
                props.data?.map((members, index) => {
                  return (
                    <tr className="border-bottom" key={index}>
                      <td className="col-2">
                        {members?.name === ""
                          ? "Profile Not Updated"
                          : members?.name}
                      </td>
                      <td className="col-2 text-center">
                        {members?.email === "" ? <p>-</p> : members?.email}
                      </td>
                      <td className="col-2 text-center">
                        {members?.phoneNo === "" ? <p>-</p> : members?.phoneNo}
                      </td>
                      <td className="col-3 text-center">
                        <ViewUsers members={members} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
