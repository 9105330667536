import React, { useRef, useState, useEffect } from "react";
import { baseURL, token } from "../../../utils/constant";
import axios from "axios";
import { Button, Form, Modal, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

export default function AddNewService(props) {
  const formRef = useRef(null);
  //
  const [modelValue, setModelValue] = useState([]);
  const [multipleCarModelChange, setMultipleCarModelChange] = useState([]);
  //

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const [inputArray, setInputArray] = useState([]);
  const servicesArray = inputArray?.map((arr) => arr);

  const [isLoading, setIsLoading] = useState(false);

  const [carBrands, setCarBrands] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [fuelTypes, setFuelTypes] = useState();
  var allCarBrandForloop = [];
  var allCarModelForloop = [];
  var allFuelTypeForloop = [];

  const [titleId, setTitleId] = useState();

  const [titles, setTitles] = useState([]);

  const [brandValue, setBrandValue] = useState();
  const [fuelTypeValue, setFuelTypeValue] = useState();

  const [submiting, setSubmiting] = useState(false);

  const [inputValues, setInputValues] = useState([
    {
      title: "",
      mrp: "",
      carTypeId: "",
      carModelId: "",
      carFualTypeId: "",

      carBrandId: "",
      currentMrp: "",
      service: "",
      discount: "",
      deliveryCharges: "",
      icons: "",
      image: "",
    },
  ]);

  const brandIdChange = inputValues?.carBrandId;

  const handleClose = () => {
    setSubmiting(false);

    setShow(false);
  };
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
    setSubmiting(false);
    handleClear();
  };

  let discountedPrice = (inputValues?.mrp * inputValues?.discount) / 100;
  let currentValue = inputValues?.mrp - discountedPrice;

  const titleName = titles?.filter((tit) => {
    return tit?._id === titleId;
  });

  // changeInputValues
  function changeInputValues(evt) {
    const value = evt.target.value;
    setInputValues({
      ...inputValues,
      [evt.target.name]: value,
    });
  }

  function carBrandChange(evt) {
    setBrandValue(evt.target.value);
    if (evt.target.value === "ALL") {
      for (let index = 0; index < carBrands.length; index++) {
        allCarBrandForloop.push(carBrands[index]);
      }
      localStorage.setItem("carBrandArray", JSON.stringify(allCarBrandForloop));
    } else if (Boolean(evt.target.value)) {
      let carBrandType = carBrands.filter((e) => e._id === evt.target.value);
      localStorage.setItem("carBrandArray", JSON.stringify(carBrandType));
    }
  }

  function carModelChange(evt) {
    console.log(evt.target.value);
    if (evt.target.value === "All") {
      for (let index = 0; index < carModels.length; index++) {
        allCarModelForloop.push(carModels[index]);
      }
      localStorage.setItem("carModelArray", JSON.stringify(allCarModelForloop));
    } else if (Boolean(evt.target.value)) {
      let carType = carModels.filter((e) => e._id === evt.target.value);
      localStorage.setItem("carModelArray", JSON.stringify(carType));
    }
  }

  function fuelTypeChange(evt) {
    setFuelTypeValue(evt.target.value);
    console.log(fuelTypes);
    if (evt.target.value === "all") {
      for (let index = 0; index < fuelTypes.length; index++) {
        allFuelTypeForloop.push(fuelTypes[index]);
      }
      localStorage.setItem(
        "fuelTypesArray",
        JSON.stringify(allFuelTypeForloop)
      );
    } else if (Boolean(evt.target.value)) {
      let fuelType = fuelTypes.filter((e) => e._id === evt.target.value);
      console.log(fuelType);
      localStorage.setItem("fuelTypesArray", JSON.stringify(fuelType));
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const brandsLoal = JSON.parse(localStorage.getItem("carBrandArray"));
    const modelsLoal = JSON.parse(localStorage.getItem("carModelArray"));
    const fuelLocal = JSON.parse(localStorage.getItem("fuelTypesArray"));
    // setValidated(true);
    const form = event.currentTarget;

    if (titleName[0]?.title === undefined) {
      toast.dismiss();
      toast.error("Select Service");
      return false;
    }
    if (!inputValues?.mrp) {
      toast.dismiss();
      toast.error("Enter MRP");
      return false;
    }
    if (!inputValues?.deliveryCharges) {
      toast.dismiss();
      toast.error("Enter Delivery Charges");
      return false;
    }
    if (!inputValues?.discount) {
      toast.dismiss();
      toast.error("Enter Discount");
      return false;
    }
    if (!inputValues?.carBrandId) {
      toast.dismiss();
      toast.error("Select Car Brand");
      return false;
    }
    if (!currentValue) {
      toast.dismiss();
      toast.error("Enter Current MRP");
      return false;
    }
    if (!servicesArray) {
      toast.dismiss();
      toast.error("Services not assign");
      return false;
    }
    if (!titleName[0]?.icon) {
      toast.dismiss();
      toast.error("Icon not assign");
      return false;
    }
    if (!titleName[0]?.image) {
      toast.dismiss();
      toast.error("Image not assign");
      return false;
    }
    if (!props?.service?._id) {
      toast.dismiss();
      toast.error("Service ID not found");
      return false;
    }
    if (!modelValue) {
      toast.dismiss();
      toast.error("Select Car Model");
      return false
    }
    if (!inputValues?.carFualTypeId) {
      toast.dismiss();
      toast.error("Select Fuel Type");
      return false;
    }
    if (!titleId) {
      toast.dismiss();
      toast.error("Reguler Service Id not assign");
      return false;
    }

    if (form.checkValidity() !== false) {
      const carArr = [];
      setSubmiting(true);
      if (
        brandValue === "ALL" &&
        modelValue.includes("All") === true &&
        fuelTypeValue === "all"
      ) {
        for (let index = 0; index < brandsLoal.length; index++) {
          var data = JSON.stringify({
            carBrandId: brandsLoal[index]._id,
          });

          var config = {
            method: "post",
            url: `${baseURL}admin/getCarModelByCarBrandId`,
            headers: {
              authorization: token,
              "Content-Type": "application/json",
            },
            data: data,
          };
          await axios(config)
            .then(async function (response) {
              const carM = response?.data?.Data;

              if (Array.isArray(carM) && carM.length > 0) {
                for (let i = 0; i < carM.length; i++) {
                  for (let j = 0; j < fuelLocal.length; j++) {
                    if (fuelLocal[j]._id !== "63864dbe025eb60a77b93ea1") {
                      const data = {
                        title: titleName[0]?.title,
                        mrp: inputValues?.mrp,
                        currentMrp: Math.round(currentValue),
                        discount: inputValues?.discount,
                        service: servicesArray,
                        icon: titleName[0]?.icon,
                        image: titleName[0]?.image,
                        subCategoryId: props?.service?._id,
                        carBrandId: brandsLoal[index]._id,
                        carModelId: carM[i]._id,
                        carFualTypeId: fuelLocal[j]._id,
                        deliveryCharges: inputValues?.deliveryCharges,
                        regulerServiceId: titleId,
                      };
                      carArr.push(data);
                    }
                  }
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      } else if (
        brandValue !== "ALL" &&
        modelValue.includes("All") === true &&
        fuelTypeValue === "all"
      ) {
        for (let z = 0; z < modelsLoal.length; z++) {
          for (let j = 0; j < fuelLocal.length; j++) {
            if (fuelLocal[j]._id !== "63864dbe025eb60a77b93ea1") {
              const data = {
                title: titleName[0]?.title,
                mrp: inputValues?.mrp,
                currentMrp: Math.round(currentValue),
                discount: inputValues?.discount,
                service: servicesArray,
                icon: titleName[0]?.icon,
                image: titleName[0]?.image,
                subCategoryId: props?.service?._id,
                carBrandId: inputValues?.carBrandId,
                carModelId: modelsLoal[z]._id,
                carFualTypeId: fuelLocal[j]._id,
                deliveryCharges: inputValues?.deliveryCharges,
                regulerServiceId: titleId,
              };
              carArr.push(data);
            }
          }
        }
      } else if (
        brandValue !== "ALL" &&
        modelValue.includes("All") !== true &&
        fuelTypeValue === "all"
      ) {
        for (let j = 0; j < fuelLocal.length; j++) {
          for (let i = 0; i < multipleCarModelChange.length; i++) {
            if (fuelLocal[j]._id !== "63864dbe025eb60a77b93ea1") {
              const data = {
                title: titleName[0]?.title,
                mrp: inputValues?.mrp,
                currentMrp: Math.round(currentValue),
                discount: inputValues?.discount,
                service: servicesArray,
                icon: titleName[0]?.icon,
                image: titleName[0]?.image,
                subCategoryId: props?.service?._id,
                carBrandId: inputValues?.carBrandId,
                carModelId: multipleCarModelChange[i]._id,
                carFualTypeId: fuelLocal[j]._id,
                deliveryCharges: inputValues?.deliveryCharges,
                regulerServiceId: titleId,
              };
              carArr.push(data);
            }
          }
        }
      } else if (
        brandValue !== "ALL" &&
        modelValue.includes("All") === true &&
        fuelTypeValue !== "all"
      ) {
        for (let i = 0; i < modelsLoal.length; i++) {
          const data = {
            title: titleName[0]?.title,
            mrp: inputValues?.mrp,
            currentMrp: Math.round(currentValue),
            discount: inputValues?.discount,
            service: servicesArray,
            icon: titleName[0]?.icon,
            image: titleName[0]?.image,
            subCategoryId: props?.service?._id,
            carBrandId: inputValues?.carBrandId,
            carModelId: modelsLoal[i]._id,
            carFualTypeId: inputValues?.carFualTypeId,
            deliveryCharges: inputValues?.deliveryCharges,
            regulerServiceId: titleId,
          };
          carArr.push(data);
        }
      } else if (
        brandValue !== "ALL" &&
        modelValue.includes("All") !== true &&
        fuelTypeValue !== "all"
      ) {
        const data = multipleCarModelChange.reduce((result, item, index) => {
          result[index] = {
            title: titleName[0]?.title,
            mrp: inputValues?.mrp,
            currentMrp: Math.round(currentValue),
            discount: inputValues?.discount,
            service: servicesArray,
            icon: titleName[0]?.icon,
            image: titleName[0]?.image,
            subCategoryId: props?.service?._id,
            carBrandId: inputValues?.carBrandId,
            carModelId: item._id,
            carFualTypeId: inputValues?.carFualTypeId,
            deliveryCharges: inputValues?.deliveryCharges,
            regulerServiceId: titleId,
          };
          return result;
        }, {});

        let keys = Object.keys(data);
        keys.forEach((e) => carArr.push(data[e]));
      } else {
        setSubmiting(false);
        setShow(false);
      }

      await axios({
        method: "post",
        url: `${baseURL}admin/addNewService_v5`,
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
        data:
          brandValue !== "ALL" &&
            modelValue.includes("All") !== true &&
            fuelTypeValue !== "all"
            ? [carArr[0]]
            : carArr,
      })
        .then((response) => {
          setTitleId("");
          carArr.length = 0;
          setSubmiting(false);
          setShow(false);
        })
        .catch((error) => {
          alert(error.message);
          setSubmiting(false);
          console.log(error);
        });
    } else {
      setShow(true);
      event.stopPropagation();
    }
  };

  // getallcartype
  async function getAllCarBrands() {
    setIsLoading(true);
    await axios({
      method: "post",
      url: `${baseURL}admin/getAllCarBrand`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const carBrands = response?.data?.Data;
        setIsLoading(false);
        setCarBrands(carBrands);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // getallcartype
  function getAllTitlesData() {
    setIsLoading(true);

    var config = {
      method: "post",
      url: `${baseURL}admin/getAllRegulerService`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const allTitlesRes = response?.data?.Data;
        setTitles(allTitlesRes);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      });
  }

  // getTitlesDataById
  function getTitlesDataById() {
    setIsLoading(true);

    var config = {
      method: "post",
      url: `${baseURL}admin/getRegulerService`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: { regulerServiceId: titleId },
    };

    axios(config)
      .then(function (response) {
        setIsLoading(false);
        const getRegulerService = response?.data?.Data[0]?.service;
        setInputArray(getRegulerService);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      });
  }

  // getAllCarModels
  function getAllCarModels() {
    setIsLoading(true);
    var data = JSON.stringify({
      carBrandId: inputValues?.carBrandId,
    });

    var config = {
      method: "post",
      url: `${baseURL}admin/getCarModelByCarBrandId`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        const carTypes = response?.data?.Data;
        setIsLoading(false);
        setCarModels(carTypes);
      })
      .catch(function (error) {
        // setSubmiting(false)
        console.log(error);
      });
  }

  // getallcartype
  function getAllFuelTypes() {
    setIsLoading(true);
    axios({
      method: "post",
      url: `${baseURL}admin/getAllFuelType`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const fuelTypes = response?.data?.Data;
        setIsLoading(false);
        setFuelTypes(fuelTypes);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // handleChangeArray
  function handleChangeArray(e, index) {
    const values = [...inputArray];
    values[index] = e.target.value;
    setInputArray(values);
  }

  // handleRemove
  function handleRemove(index) {
    const value = [...inputArray];
    value.splice(index, 1);
    setInputArray(value);
  }

  // clear form when open popup
  const handleClear = (e) => {
    // e.preventDefault();
    // setValidated(false);
    // formRef.current.reset();
    setInputValues([
      {
        title: "",
        mrp: "",
        carTypeId: "",
        carModelId: "",
        carFualTypeId: "",

        carBrandId: "",
        currentMrp: "",
        service: "",
        discount: "",
        deliveryCharges: "",
        icons: "",
        image: "",
      },
    ]);

    setInputArray([]);
    setModelValue("");
    // setFuelTypeValue("")
  };

  // get all car brands
  useEffect(() => {
    if (show) {
      getAllCarBrands();
      getAllTitlesData();
      getAllFuelTypes();
    }
  }, [show]);

  useEffect(() => {
    show && getTitlesDataById();
  }, [titleId]);

  // getAllCarModels
  useEffect(() => {
    if (show) {
      if (brandValue === "ALL") {
        setCarModels([]);
      } else {
        getAllCarModels();
      }
    }
  }, [brandIdChange]);

  return (
    <>
      <Form
        ref={formRef}
        onSubmit={(e) => {
          handleClear(e);
        }}
        noValidate
      // validated={validated}
      >
        <button
          type="submit"
          className="btn btn-info"
          onClick={(e) => handleShow(e)}
        >
          + Subcategory
        </button>
      </Form>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          noValidate
        // validated={validated}
        >
          <Modal.Header closeButton>
            <Modal.Title>Subcategory</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
              // controlId="validationCustom04"
              >
                <Form.Label>Service</Form.Label>
                <Form.Select
                  className="form-control-sm"
                  aria-label="Default select example"
                  name="title"
                  value={inputValues?.title ? inputValues?.title : ""}
                  onChange={(e) => {
                    changeInputValues(e);
                    setTitleId(e.target.value);
                  }}
                >
                  <option>Select</option>
                  <option disabled>
                    {titles?.length === 0
                      ? `Car title (0)`
                      : `Car Title (${titles?.length})`}{" "}
                  </option>

                  {isLoading ? (
                    <option disabled>Loading..</option>
                  ) : (
                    titles?.map((title, key) => {
                      return (
                        <option value={title?._id} key={key}>
                          {title?.title}
                        </option>
                      );
                    })
                  )}
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Mrp</Form.Label>
                <Form.Control
                  type="number"
                  name="mrp"
                  value={inputValues?.mrp < 0 ? 0 : inputValues?.mrp || ""}
                  onChange={(e) => changeInputValues(e)}
                  placeholder="mrp"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Delivery Charges</Form.Label>
                <Form.Control
                  type="text"
                  name="deliveryCharges"
                  value={inputValues?.deliveryCharges || ""}
                  onChange={(e) => changeInputValues(e)}
                  placeholder="delivery charges"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Discount</Form.Label>
                <Form.Control
                  type="number"
                  name="discount"
                  value={
                    inputValues?.discount < 0
                      ? (inputValues.discount = 0)
                      : inputValues?.discount &&
                        inputValues?.discount?.length >= 3
                        ? (inputValues.discount = 100)
                        : inputValues?.discount || ""
                  }
                  onChange={(e) => changeInputValues(e)}
                  placeholder="discount"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Car Brand</Form.Label>
                <Form.Select
                  className="form-control-sm"
                  aria-label="Default select example"
                  name="carBrandId"
                  required
                  value={inputValues?.carBrandId || ""}
                  onChange={(e) => {
                    changeInputValues(e);
                    carBrandChange(e);
                  }}
                >
                  <option>Select</option>
                  <option disabled>
                    {carBrands?.length === 0
                      ? `No car brand`
                      : `Car Brand (${carBrands?.length})`}{" "}
                  </option>
                  {carBrands?.length > 1 ? (
                    <option value="ALL">All</option>
                  ) : (
                    ""
                  )}
                  {isLoading ? (
                    <option disabled>Loading..</option>
                  ) : (
                    carBrands?.map((brand, key) => {
                      return (
                        <option value={brand?._id} key={key}>
                          {brand?.brandName}
                        </option>
                      );
                    })
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Current Mrp</Form.Label>
                <Form.Control
                  type="number"
                  name="currentMrp"
                  value={currentValue || ""}
                  onChange={(e) => changeInputValues(e)}
                  placeholder="current mrp"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Car Model</Form.Label>
                <Form.Select
                  className="form-control-sm"
                  aria-label="Default select example"
                  name="carModelId"
                  multiple
                  value={modelValue}
                  onChange={(e) => {
                    const selectedOptions = [].slice.call(
                      e.target.selectedOptions
                    );
                    const selectedIDs = selectedOptions.map(
                      (item) => item.value
                    );
                    setModelValue(selectedIDs);
                    const selectedModels = carModels.filter((model) =>
                      selectedIDs.includes(model._id)
                    );
                    setMultipleCarModelChange(selectedModels);

                    carModelChange(e);
                  }}
                  required
                  style={{
                    height: "10rem",
                  }}
                >
                  <option>Select</option>
                  <option disabled>
                    {carModels?.length === 0
                      ? `No car models`
                      : `Car Models (${carModels?.length})`}{" "}
                  </option>
                  {carModels?.length > 1 ? (
                    <option value="All">All</option>
                  ) : brandValue === "ALL" ? (
                    <option value="All">All</option>
                  ) : (
                    ""
                  )}
                  {isLoading ? (
                    <option disabled>Loading..</option>
                  ) : (
                    carModels?.map((model, key) => {
                      return (
                        <option value={model?._id} key={key}>
                          {model?.modelName}
                        </option>
                      );
                    })
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
              {/* {console.log("inputValues?.title===", inputValues?.title)}
              {console.log("modelValue===", modelValue)}
              {console.log(
                "inputValues?.carBrandId===",
                inputValues?.carBrandId
              )}
              {console.log(
                "inputValues?.carFualTypeId===",
                inputValues?.carFualTypeId
              )} */}
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom05"
              >
                <Form.Label>Fuel Type</Form.Label>
                <Form.Select
                  className="form-control-sm"
                  aria-label="Default select example"
                  name="carFualTypeId"
                  value={
                    inputValues?.carFualTypeId ? inputValues?.carFualTypeId : ""
                  }
                  onChange={(e) => {
                    changeInputValues(e);
                    fuelTypeChange(e);
                  }}
                  required
                >
                  <option>Select</option>
                  <option disabled>
                    {fuelTypes?.length === 0
                      ? `No fuel type`
                      : `fuel type (${fuelTypes?.length})`}{" "}
                  </option>
                  {fuelTypes?.length > 1 ? (
                    <option value="all">All</option>
                  ) : (
                    ""
                  )}
                  {isLoading ? (
                    <option disabled>Loading..</option>
                  ) : (
                    fuelTypes?.map((fType, index) => {
                      return (
                        <option key={index} value={fType?._id}>
                          {fType?.fuelType}
                        </option>
                      );
                    })
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <hr />
            <Row>
              <Form.Group
                className="col-lg-12 mb-2"
                md="3"
                controlId="validationCustom04"
              >
                <div>
                  <div style={{ textAlign: "right" }}>
                    <Form.Label>
                      Services (
                      {inputArray?.length <= 0 ? "No data" : inputArray?.length}
                      ){" "}
                    </Form.Label>
                  </div>
                  {inputArray?.map((item, index) => {
                    return (
                      <div key={index} className="mb-2">
                        <Form.Label>Service {index + 1} </Form.Label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Control
                            style={{ width: "90%" }}
                            type="text"
                            name="fname"
                            value={item || ""}
                            disabled
                            onChange={(e) => handleChangeArray(e, index)}
                            placeholder="service"
                            required
                          />
                          <Button
                            disabled={inputArray.length === 1 && true}
                            variant="danger"
                            style={{
                              width: "8% ",
                              margin: "0 0 0 6px",
                              fontWeight: "700",
                            }}
                            onClick={() => handleRemove(index)}
                          >
                            -
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <hr />
              </Form.Group>
            </Row>
            <div className="d-md-flex justify-content-between">
              <Form.Group
                className="pr-2"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label className="col form-label pl-0">Image</Form.Label>
                {titleName[0]?.image !== null ||
                  titleName[0]?.image !== undefined ||
                  titleName[0]?.image !== "" ? (
                  <img
                    className="w-100"
                    src={`${baseURL + titleName[0]?.image}`}
                    alt="No img"
                  />
                ) : (
                  "No img"
                )}
              </Form.Group>

              <Form.Group md="3" controlId="validationCustom04">
                <Form.Label className="col form-label pl-0">Icon</Form.Label>
                {titleName[0]?.icon !== null ||
                  titleName[0]?.icon !== undefined ||
                  titleName[0]?.icon !== "" ? (
                  <img
                    className="w-100"
                    src={`${baseURL + titleName[0]?.icon}`}
                    alt="No icon"
                  />
                ) : (
                  "No icon"
                )}
              </Form.Group>
            </div>
          </Modal.Body>
          {submiting ? (
            <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
              <Spinner animation="border" style={{ color: "#223385" }} /> Adding
              Subcategory..
            </Modal.Footer>
          ) : (
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" className="btn-success" varint="success">
                {"Submit"}
              </Button>
            </Modal.Footer>
          )}
        </Form>
      </Modal>
    </>
  );
}
