import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { baseURL, token } from "../../../utils/constant";
import UpdateCarModelImageTable from "../../Tables/UpdateCarModelImageTable";

export default function UpdateCarModelImage() {
  const [carModelByBrand, setCarModelByBrand] = useState();
  const [allBrand, setAllBrand] = useState([]);
  const [allUpdateCarModel, setAllUpdateCarModel] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [image, setImage] = useState();

  const [loader, setLoader] = useState(false);

  const inputRef = useRef(null);

  async function getallUpdateCarModels() {
    setLoader(true);
    await axios({
      method: "post",
      url: `${baseURL}admin/getCarModelByCarBrandId`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        carBrandId: carModelByBrand,
      }),
    })
      .then((response) => {
        setLoader(false);
        setAllUpdateCarModel(response.data.Data);
      })
      .catch((error) => {
        setLoader(false);
        setAllUpdateCarModel(""); // Add for blank list when not selected any car brand.
        console.error(error);
      });
  }

  async function apiCall() {
    await axios({
      url: `${baseURL}admin/UpdateCarModel`,
      method: "POST",
      headers: {
        authorization: token,
      },
      data: {
        carModelId: selectedId,
        image: image,
      },
    }).then((res) => {
      getallUpdateCarModels();
    });
  }

  async function getAllCarBrnds() {
    await axios({
      method: "post",
      url: `${baseURL}admin/getAllCarBrand`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setAllBrand(response.data.Data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const profileImagetoBase64 = (e) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => resolve(setImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  };

  // Get Id for Image
  const handleClick = (id) => {
    inputRef.current.click();
    setSelectedId(id);
  };

  useEffect(() => {
    getAllCarBrnds();
  }, []);

  useEffect(() => {
    image && apiCall();
  }, [image]);

  useEffect(() => {
    carModelByBrand && getallUpdateCarModels();
  }, [carModelByBrand]);
  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="row">
            <div className="col-12 col-xl-10 mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Update Car Model</h3>
              <b>
                {allUpdateCarModel
                  ? `${allUpdateCarModel?.length} Car model`
                  : ``}
              </b>
            </div>
          </div>
        </div>
      </div>

      <UpdateCarModelImageTable
        setCarModelByBrand={setCarModelByBrand}
        allBrand={allBrand}
        loader={loader}
        allUpdateCarModel={allUpdateCarModel}
        inputRef={inputRef}
        profileImagetoBase64={profileImagetoBase64}
        handleClick={handleClick}
      />
    </>
  );
}
