import React, { useEffect, useState } from "react";
import { baseURL, token } from "../utils/constant";
import ViewSOSEmergencyTable from "./Tables/ViewSOSEmergencyTable";

export default function ViewSOSEmergency() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  async function getAllEmergency() {
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);

    const raw = "";
    setLoader(true);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseURL}admin/getAllUserSOS`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.Data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  }

  useEffect(() => {
    getAllEmergency();
  }, []);
  
  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="row">
            <div className="col-12 col-xl-10 mb-4 mb-xl-0">
              <h3 className="font-weight-bold">View SOS Emergency</h3>
            </div>
          </div>
        </div>
      </div>

      <ViewSOSEmergencyTable loader={loader} data={data} />
    </>
  );
}
