import React from "react";
import NoDataFound from "../../utils/NoDataFound";
import ViewVender from "../Pages/ManageMembers/ViewVender";

export default function ShowVenderTable(props) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive pt-3">
          <table
            className={`table table-bordered ${props.loader && "loading"}`}
          >
            <thead className="text-center">
              <tr>
                <th>Workshop Name</th>
                <th>Owner Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.data && props.data.length > 0 ? (
                props.data?.map((vender, index) => {
                  return (
                    <tr className="border-bottom" key={index}>
                      <td>{vender.workshopName}</td>
                      <td>{vender.ownerName}</td>
                      <td className="text-center">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() =>
                            props.deleteVender(vender._id)
                          }
                          style={{ margin: "0 15px", cursor: "pointer" }}
                          width="20"
                          height="20"
                          fill="red"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path
                            fillRule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                          />
                        </svg>
                        <ViewVender vender={vender} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
