import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { baseURL, token } from "../../../utils/constant";
import axios from "axios";
import CarmodelTable from "../../Tables/CarmodelTable";

export default function Carmodel() {
  const inputRef = useRef(null);

  const [allCarModel, setAllCarModel] = useState([]);
  const [isEditEvent, setIsEditEvent] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [loader, setLoader] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [modelName, setModelName] = useState();
  const [modelImage, setmodelImage] = useState();
  const [brandId, setBrandId] = useState();
  const [carTypeId, setCarTypeId] = useState();
  const [carBrands, setCarBrands] = useState([]);
  const [carTypes, setCarTypes] = useState([]);
  const [isImageUpdate, setIsImageUpdate] = useState(false);
  const [allFuelType, setAllFuelType] = useState();

  const handleClose = () => {
    setShow(false);
    setIsEditEvent(false);
  };
  const handleShow = () => {
    setShow(true);
    setModelName("");
    setCarTypeId("");
    setBrandId("");
  };
  const [fuelId, setFuelId] = useState();

  async function getAllCarModels() {
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);
    const raw = "";
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    setLoader(true);
    await fetch(`${baseURL}admin/getAllCarModel`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCarModel(result.Data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  }

  function getAllFuel() {
    axios({
      url: `${baseURL}admin/getAllFuelType`,
      method: "POST",
      headers: {
        authorization: token,
      },
    }).then((res) => {
      setAllFuelType(res.data.Data);
    });
  }

  async function updateCarModal(carID, active) {
    await axios({
      url: `${baseURL}admin/UpdateCarModel`,
      method: "POST",
      headers: {
        authorization: token,
      },
      data: {
        carModelId: carID,
        isActive: active,
      },
    }).then((res) => getAllCarModels());
  }

  const profileImagetoBase64 = (e) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => resolve(setmodelImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (isEditEvent) {
      await axios({
        url: `${baseURL}admin/UpdateCarModel`,
        method: "POST",
        headers: {
          authorization: token,
        },
        data: {
          carModelId: selectedId,
          modelName: modelName,
          carBrandId: brandId,
          carTypeId: carTypeId,
          image: isImageUpdate && modelImage,
        },
      })
        .then((result) => {
          setValidated(false);
          setShow(false);
          getAllCarModels();
        })
        .catch((error) => console.log("error", error));
      setIsImageUpdate(false);
    }

    if (!isEditEvent) {
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        setIsAdding(true);
        await axios({
          url: `${baseURL}admin/addNewCarModel`,
          method: "POST",
          headers: {
            authorization: token,
          },
          data: {
            modelName: modelName,
            carBrandId: brandId,
            carTypeId: carTypeId,
            image: modelImage,
            fuelTypeId: fuelId,
          },
        })
          .then((result) => {
            setValidated(false);
            setShow(false);
            setIsAdding(false);
            getAllCarModels();
          })
          .catch((error) => console.log("error", error));
      }
    }
    setValidated(true);
  };

  // deleteModel
  const deleteModel = async (id) => {
    const selected = allCarModel?.filter((brand) => brand?._id === id);

    var myHeaders = new Headers();
    myHeaders.append("authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      carModelId: selected[0]?._id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    if (window.confirm("Delete?")) {
      await fetch(`${baseURL}admin/deleteCarModel_v1`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          getAllCarModels();
        })
        .catch((error) => console.log("error", error));
    }
  };

  // edit brand
  function editBrand(id) {
    handleShow();
    const selectedBrand = allCarModel?.filter((brand) => brand?._id === id);
    const selemodelName = selectedBrand[0]?.modelName;
    const selemodelImage = selectedBrand[0]?.image;
    const selectCarBrandId = selectedBrand[0]?.CarBrandDetails[0]._id;
    const selectCarTypeId = selectedBrand[0]?.CarTypeDetails[0]._id;

    setIsEditEvent(true);

    setModelName(selemodelName);
    setmodelImage(selemodelImage);
    setBrandId(selectCarBrandId);
    setCarTypeId(selectCarTypeId);

    setSelectedId(selectedBrand[0]?._id);
  }

  async function getAllCarBrands() {
    await axios({
      method: "post",
      url: `${baseURL}admin/getAllCarBrand`,
      headers: {
        authorization: token,
      },
    })
      .then((response) => {
        setCarBrands(response?.data?.Data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getAllCarTypes() {
    await axios({
      method: "post",
      url: `${baseURL}admin/getAllCarType`,
      headers: {
        authorization: token,
      },
    })
      .then((response) => {
        setCarTypes(response?.data?.Data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getAllCarModels();
    getAllCarBrands();
    getAllCarTypes();
    getAllFuel();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Car Model</h3>
              <b> {allCarModel ? `${allCarModel?.length} Car model` : ``} </b>
            </div>
            <button className="btn btn-primary p-2" onClick={handleShow}>
              + New Model
            </button>
          </div>
        </div>
      </div>

      <CarmodelTable
        loader={loader}
        allCarModel={allCarModel}
        deleteModel={deleteModel}
        editBrand={editBrand}
        updateCarModal={updateCarModal}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditEvent ? `Edit Model` : `New Model`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="col-lg-12 mb-3" md="3">
                <Form.Label>Car Brand</Form.Label>
                <Form.Select
                  className="form-control-sm col-lg-12"
                  required
                  value={brandId}
                  onChange={(e) => setBrandId(e.target.value)}
                >
                  <option>Select</option>
                  {carBrands ? (
                    carBrands?.map((brand, index) => {
                      return (
                        <option key={index} value={brand?._id}>
                          {brand?.brandName}
                        </option>
                      );
                    })
                  ) : (
                    <option>No data</option>
                  )}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="col-lg-12 mb-3" md="3">
                <Form.Label>Car Type</Form.Label>
                <Form.Select
                  className="form-control-sm col-lg-12"
                  required
                  value={carTypeId}
                  onChange={(e) => setCarTypeId(e.target.value)}
                >
                  <option>Select</option>
                  {carTypes ? (
                    carTypes?.map((type, index) => {
                      return (
                        <option key={index} value={type?._id}>
                          {type?.carType}
                        </option>
                      );
                    })
                  ) : (
                    <option>No data</option>
                  )}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  ref={inputRef}
                  placeholder="image"
                  onChange={(e) => {
                    profileImagetoBase64(e);
                    setIsImageUpdate(true);
                  }}
                  required={isEditEvent ? false : true}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6"
                md="3"
                controlId="validationCustom05"
              >
                <Form.Label>Model Name</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="text"
                  value={modelName}
                  placeholder="model name"
                  onChange={(e) => setModelName(e.target.value)}
                  required={isEditEvent ? false : true}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>

                <Form.Group>
                  <Form.Label>Car Fuel Type</Form.Label>
                  <Form.Select
                    className="form-control-sm col-lg-12"
                    required
                    value={fuelId}
                    onChange={(e) => setFuelId(e.target.value)}
                  >
                    <option>Select</option>
                    {allFuelType ? (
                      allFuelType?.map((fuel, index) => {
                        return (
                          <option key={index} value={fuel?._id}>
                            {fuel?.fuelType}
                          </option>
                        );
                      })
                    ) : (
                      <option>No data</option>
                    )}
                  </Form.Select>
                </Form.Group>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="success" disabled={isAdding && true}>
              {isAdding ? "Submiting.." : "Submit"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
