import React from "react";
import NoDataFound from "../../utils/NoDataFound";
import { baseURL } from "../../utils/constant";

export default function ShowUsersTable(props) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive pt-3">
          <table
            className={`table table-bordered ${props.loader && "loading"}`}
          >
            <thead className="text-center">
              <tr>
                <th>Membership Title</th>
                <th>User Name</th>
                <th>Email </th>
                <th>Phone </th>
                <th>Car Number</th>
                <th>Model Name</th>
                <th>Image</th>
                <th>Total Month</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.data && props.data.length > 0 ? (
                props.data?.map((members, index) => {
                  return (
                    <tr className="border-bottom" key={index}>
                      <td className="col-2 text-center">
                        {members?.membershipTitle[0] === "" ? (
                          <p>-</p>
                        ) : (
                          members?.membershipTitle[0]
                        )}
                      </td>
                      <td className="col-2">
                        {members?.userName === ""
                          ? "Profile Not Updated"
                          : members?.userName}
                      </td>
                      <td className="col-2 text-center">
                        {members?.email === "" ? <p>-</p> : members?.email}
                      </td>
                      <td className="col-2 text-center">
                        {members?.phoneNo === "" ? <p>-</p> : members?.phoneNo}
                      </td>
                      <td className="col-2 text-center">
                        {members?.carNumber === "" ? (
                          <p>-</p>
                        ) : (
                          members?.carNumber
                        )}
                      </td>
                      <td className="col-2 text-center">
                        {members?.car[0].modelName === "" ? (
                          <p>-</p>
                        ) : (
                          members?.car[0].modelName
                        )}
                      </td>
                      <td className="col-2 text-center">
                        {members.car[0].image === "" ? (
                          <p>-</p>
                        ) : (
                          <img
                            style={{ height: "auto", width: "65px" }}
                            src={
                              members.car[0].image === "" ? (
                                <p>-</p>
                              ) : (
                                baseURL + members.car[0].image
                              )
                            }
                            alt="."
                          />
                        )}
                      </td>
                      <td className="col-2 text-center">
                        {members?.totalMonth[0] === "" ? (
                          <p>-</p>
                        ) : (
                          members?.totalMonth[0]
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
