import React, { useEffect, Suspense } from "react";
import { toast } from "react-toastify";
import { messaging } from "./utils/firebase";
import { getToken, onMessage } from "firebase/messaging";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "./utils/PageNotFound";
import Layout from "./components/Layout/Layout";
import Dashboard from "./components/Pages/Dashboard/Dashboard";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Service from "./components/Pages/Service/Service";
import ServiceSubCategory from "./components/Pages/Service/ServiceSubCategory";
import SOSEmergency from "./components/SOSEmergency";
import Banner from "./components/Banner";
import Fueltype from "./components/Pages/CarType/Fueltype";
import Cartype from "./components/Pages/CarType/Cartype";
import CarBrand from "./components/Pages/ManageCar/CarBrand";
import Carmodel from "./components/Pages/ManageCar/Carmodel";
import UpdateCarModelImage from "./components/Pages/ManageCar/UpdateCarModelImage";
import Carmodelfuel from "./components/Pages/ManageCar/Carmodelfuel";
import DiscountCoupon from "./components/DiscountCoupon";
import Membership from "./components/Pages/Membership/Membership";
import ShowUsers from "./components/Pages/ManageMembers/ShowUsers";
import ShowMembers from "./components/Pages/ManageMembers/ShowMembers";
import ShowVender from "./components/Pages/ManageMembers/ShowVender";
import VenderApprovedByAdmin from "./components/Pages/ManageMembers/VenderApprovedByAdmin";
import UserJobcartApproved from "./components/Pages/ManageMembers/UserJobcartApproved";
import ViewSOSEmergency from "./components/ViewSOSEmergency";
import Razorpay from "./components/Razorpay";
import ShowMembersBills from "./components/Pages/ManageMembers/ShowMembersBills";
import axios from "axios";
import { baseURL, token, isProduction } from "./utils/constant";

const Login = React.lazy(() => import("./components/Layout/Auth/Login"));

function App() {
  //
  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      if (isProduction) {
        if ('serviceWorker' in navigator) {
          console.log("Service worker started!")
          let registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
          const fcmToken = await getToken(messaging, {
            serviceWorkerRegistration: registration,
            vapidKey: "BNhmUmDvywh54ioe-2AvsDqTuri9_74piw4bwFzhGy62q3bn_7kQ8qT7VC3Jy-jognlggR3U8iWJ2jCUbp2Ha40",
          });
          console.log("FCM Token ====> ", fcmToken);
          if (fcmToken) {
            onMessage(messaging, (payload) => {
              console.log('Message received. ', payload);
              toast.success(`title - ${payload?.notification?.title}, body - ${payload?.notification?.body}`,
                {
                  position: "top-center",
                  theme: "dark",
                  autoClose: 3600000,
                }
              );
            });
            var data = { token: fcmToken };
            await axios.post(`${baseURL}admin/addNotificationToken`, data, {
              headers: { authorization: token }
            }).then((response) => console.log("response===>", response))
              .catch((error) => console.log("error===>", error));
          }
        }
      }
    } else {
      alert("You denied for the notification");
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);
  //

  return (
    <BrowserRouter>
      <ToastContainer transition={Zoom} />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route exact path="/" element={<Login />} />

          <Route element={<Layout />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/service" element={<Service />} />
            <Route
              exact
              path="/serviceSubCategory"
              element={<ServiceSubCategory />}
            />
            <Route exact path="/SOSEmergency" element={<SOSEmergency />} />
            <Route exact path="/banner" element={<Banner />} />

            <Route exact path="/fueltype" element={<Fueltype />} />
            <Route exact path="/cartype" element={<Cartype />} />

            <Route exact path="/carBrand" element={<CarBrand />} />
            <Route exact path="/carmodel" element={<Carmodel />} />
            <Route
              exact
              path="/updateCarModelImage"
              element={<UpdateCarModelImage />}
            />
            <Route exact path="/carmodelfuel" element={<Carmodelfuel />} />

            <Route exact path="/discountCoupon" element={<DiscountCoupon />} />
            <Route exact path="/membership" element={<Membership />} />

            <Route exact path="/showUsers" element={<ShowUsers />} />
            <Route exact path="/showMembers" element={<ShowMembers />} />

            <Route exact path="/showVender" element={<ShowVender />} />
            <Route
              exact
              path="/venderApprovedByAdmin"
              element={<VenderApprovedByAdmin />}
            />
            <Route
              exact
              path="/userJobcartApproved"
              element={<UserJobcartApproved />}
            />

            <Route
              exact
              path="/viewSOSEmergency"
              element={<ViewSOSEmergency />}
            />
            <Route exact path="/razorPay" element={<Razorpay />} />

            <Route
              exact
              path="/showMembersBills"
              element={<ShowMembersBills />}
            />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
