import React from "react";
import NoDataFound from "../../utils/NoDataFound";
import { baseURL } from "../../utils/constant";

export default function CarmodelfuelTable(props) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive pt-3">
          <table
            className={`table table-bordered ${props.loader && "loading"}`}
          >
            <thead className="text-center">
              <tr>
                <th>#</th>
                <th>Model Image</th>
                <th>Model Name</th>
                <th>Fuel Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.allCarModelFuel && props.allCarModelFuel.length > 0 ? (
                props.allCarModelFuel?.map((modelFuel, index) => {
                  return (
                    <tr className="border-bottom" key={index}>
                      <td className="col-1">{index + 1} </td>
                      <td className="col-2 text-center">
                        <img
                          style={{ height: "auto", width: "100px" }}
                          src={baseURL + modelFuel?.carmodelsdDetails[0]?.image}
                          alt=""
                        />
                      </td>
                      <td className="col-1">
                        {modelFuel?.carmodelsdDetails[0]?.modelName}{" "}
                      </td>
                      <td className="col-1">
                        {modelFuel?.fueltypesdDetails[0]?.fuelType}{" "}
                      </td>
                      <td className="col-1 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => props.deleteBrand(modelFuel?._id)}
                          style={{ cursor: "pointer" }}
                          width="20"
                          height="20"
                          fill="red"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path
                            fillRule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                          />
                        </svg>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
