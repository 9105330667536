import React from 'react'
import NoDataFound from '../../utils/NoDataFound';

export default function VenderApprovedByAdminTable(props) {
  return (
    <div className="card">
        <div className="card-body">
          <div className="table-responsive pt-3">
            <table className={`table table-bordered ${props.loader && "loading"}`}>
              <thead className="text-center">
                <tr>
                  <th>#</th>
                  <th>Workshop Name</th>
                  <th>Workshop Address</th>
                  <th>Phone</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {props.data && props.data.length > 0 ? (
                  props.data?.map((service, index) => {
                    return (
                      <tr className="border-bottom" key={index}>
                        <td>{index + 1} </td>
                        <td>{service?.workshopName} </td>
                        <td>{service?.workshopAddress} </td>
                        <td>{service?.phone} </td>
                        <td className="text-center">
                          <button
                            className="btn btn-primary"
                            onClick={() => props.handleShow(service?._id, service)}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoDataFound />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
  )
}
