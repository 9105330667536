import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC11gPQ4PBqjHgyk3wN7b5lur0ti6Jem3M",
  authDomain: "real-motor-workshop.firebaseapp.com",
  projectId: "real-motor-workshop",
  storageBucket: "real-motor-workshop.appspot.com",
  messagingSenderId: "539165144836",
  appId: "1:539165144836:web:1a9c267af45f8153a1c251",
  measurementId: "G-Y7QB7W54NR"
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);