import React from "react";

export default function PageNotFound() {
  return (
    <>
      <style jsx="true">
        {`
          .page-not-found {
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}
      </style>
      <div className="page-not-found">
        <h1>Page Not Found</h1>
      </div>
    </>
  );
}
