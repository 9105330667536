import React from "react";
import ViewMembership from "../Pages/Membership/ViewMembership";
import NoDataFound from "../../utils/NoDataFound";

export default function MembershipTable(props) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive pt-3">
          <table
            className={`table table-bordered ${props.loader && "loading"}`}
          >
            <thead className="text-center">
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Description</th>
                <th>Time Limit</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.allMembership && props.allMembership.length > 0 ? (
                props.allMembership?.map((membership, index) => {
                  return (
                    <tr className="border-bottom" key={index}>
                      <td className="col-1">{index + 1} </td>
                      <td className="col-2"> {membership?.title} </td>
                      <td className="col-3">{membership?.description}</td>
                      <td className="col-1">{membership?.totalMonth}</td>
                      <td className="col-2"> {membership?.price} </td>
                      <td className="col-3 text-center">
                        <svg
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props.deleteMembership(membership?._id)
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="red"
                          className="bi bi-archive-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => props.handleEditItems(membership)}
                          style={{ margin: "0 15px", cursor: "pointer" }}
                          width="20"
                          height="20"
                          fill="#223385"
                          className="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fillRule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                          />
                        </svg>
                        <ViewMembership
                          servicesAll={props.services}
                          membership={membership}
                          serviceIndex={props.setINdexOfInputArrView}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
