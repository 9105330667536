import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

export default function Layout() {
  const authDetails = localStorage.getItem("isLogin");
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [menuActive, setMenuActive] = useState(false);
  const toggleSidebar = () => {
    setMenuActive(!menuActive)
  }

  return (
    <>
      {!authDetails ? (
        <Navigate to="/" />
      ) : (
        <div className="container-scroller">
          <Header toggleSidebar={toggleSidebar}/>

          <div className="container-fluid page-body-wrapper pl-0 pr-0">
            <div className="theme-setting-wrapper" onClick={goToTop}>
              <div id="settings-trigger">
                <i className="mdi mdi-arrow-up-bold"></i>
              </div>
            </div>

            <Sidebar goToTop={goToTop} menuActive={menuActive}/>

            <div className="main-panel">
              <div className="content-wrapper">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
