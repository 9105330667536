import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { baseURL, token } from "../../../utils/constant";
import axios from "axios";
import CarmodelfuelTable from "../../Tables/CarmodelfuelTable";

export default function Carmodelfuel() {
  const [loader, setLoader] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const [fuelTypeId, setFuelTypeId] = useState();

  const [allFuelType, setAllFuelType] = useState([]);

  const [allCarModelFuel, setAllCarModelFuel] = useState([]);

  const [allCarBrands, setAllCarBrands] = useState([]);

  const [modelId, setModelId] = useState();

  const [changeBrandId, setChangeBrandId] = useState();

  const [allCarModels, setAllCarModels] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function getAllCarBrand() {
    await axios({
      url: `${baseURL}admin/getAllCarBrand`,
      method: "POST",
      headers: {
        authorization: token,
      },
    })
      .then((res) => {
        setAllCarBrands(res.data.Data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function getAllCarModelFuel() {
    setLoader(true);
    await axios({
      url: `${baseURL}admin/getAllCarModelFuel`,
      method: "POST",
      headers: {
        authorization: token,
      },
    })
      .then((res) => {
        setLoader(false);
        setAllCarModelFuel(res.data.Data);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  }

  async function getAllFuel() {
    await axios({
      url: `${baseURL}admin/getAllFuelType`,
      method: "POST",
      headers: {
        authorization: token,
      },
    }).then((res) => {
      setAllFuelType(res.data.Data);
    });
  }

  async function getAllCarModels() {
    await axios({
      url: `${baseURL}admin/getCarModelByCarBrandId`,
      method: "POST",
      data: { carBrandId: changeBrandId },
      headers: {
        authorization: token,
      },
    }).then((res) => {
      setAllCarModels(res.data.Data);
    });
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setIsAdding(true);
      var data = JSON.stringify({
        carModelId: modelId,
        fuelTypeId: fuelTypeId,
      });

      var config = {
        method: "post",
        url: `${baseURL}admin/addNewCarModelFuel`,
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios(config)
        .then(function (response) {
          setIsAdding(false);
          setShow(false);
          getAllCarModelFuel();
        })
        .catch(function (error) {
          setIsAdding(false);
          console.log(error);
        });
    }
    setValidated(true);
  };

  //  --------------Delete Fuel Type --------------------
  const deleteBrand = async (id) => {
    const selected = allCarModelFuel?.filter(
      (carModelfuel) => carModelfuel?._id === id
    );

    var data = JSON.stringify({
      carModelFuelId: selected[0]?._id,
    });

    var config = {
      method: "post",
      url: `${baseURL}admin/deleteCarModelFuel`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    if (window.confirm("Delete?")) {
      await axios(config)
        .then(function (response) {
          getAllCarModelFuel();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getAllCarBrand();
    getAllCarModelFuel();
  }, []);

  useEffect(() => {
    if (changeBrandId) {
      getAllFuel();
      getAllCarModels();
    }
  }, [changeBrandId]);

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Car Model Fuel</h3>
              <b>
                {allCarModelFuel
                  ? `${allCarModelFuel?.length} Car model fuel`
                  : ``}
              </b>
            </div>
            <button className="btn btn-primary p-2" onClick={handleShow}>
              + New Model Fuel
            </button>
          </div>
        </div>
      </div>

      <CarmodelfuelTable
        loader={loader}
        allCarModelFuel={allCarModelFuel}
        deleteBrand={deleteBrand}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Car Model Fuel </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Label>Car Brand</Form.Label>
              <Form.Group className="" md="3" controlId="validationCustom05">
                <Form.Select
                  className="form-control-sm"
                  required
                  onChange={(e) => setChangeBrandId(e.target.value)}
                  aria-label="Default select example"
                >
                  <option>Select</option>
                  <option disabled>{allCarBrands?.length} car brand</option>
                  {allCarBrands ? (
                    allCarBrands?.map((brand, key) => {
                      return (
                        <option value={brand?._id} key={key}>
                          {brand?.brandName}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">No data</option>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group md="3" controlId="validationCustom05">
                <Form.Label>Car Model</Form.Label>
                <Form.Select
                  className="form-control-sm"
                  required
                  onChange={(e) => setModelId(e.target.value)}
                  aria-label="Default select example"
                >
                  <option>Select</option>
                  <option disabled>{allCarModels?.length} car model</option>
                  {allCarModels ? (
                    allCarModels?.map((model) => {
                      return (
                        <option value={model?._id}>{model?.modelName}</option>
                      );
                    })
                  ) : (
                    <option value="">No data</option>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group md="3" controlId="validationCustom05">
                <Form.Label>Fuel Type</Form.Label>
                <Form.Select
                  className="form-control-sm"
                  required
                  aria-label="Default select example"
                  onChange={(e) => setFuelTypeId(e.target.value)}
                >
                  <option>Select</option>
                  {allFuelType ? (
                    allFuelType?.map((fuel) => {
                      return (
                        <option value={fuel?._id}> {fuel?.fuelType} </option>
                      );
                    })
                  ) : (
                    <option value="">No data</option>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <p></p>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="success" disabled={isAdding && true}>
              {isAdding ? "Submiting.." : "Submit"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
