import React from "react";
import NoDataFound from "../../utils/NoDataFound";
import { baseURL } from "../../utils/constant";

export default function UpdateCarModelImageTable(props) {
  return (
    <div className="card">
      <div className="card-body">
        <label htmlFor="changeVal" value="Select Car Brand">
          <select
            className="form-control-sm"
            id="changeVal"
            onChange={(e) => {
              props.setCarModelByBrand(e.target.value);
            }}
            aria-label="Default select example"
          >
            <option>
              Select Car Brand (
              {props.allBrand?.length !== 0
                ? props.allBrand?.length
                : "No brand"}
              )
            </option>
            {props.allBrand ? (
              props.allBrand?.map((brand, index) => {
                return (
                  <option key={index} value={brand._id}>
                    {brand.brandName}
                  </option>
                );
              })
            ) : (
              <h5>No data</h5>
            )}
          </select>
        </label>
        <div className="table-responsive pt-3">
          <table
            className={`table table-bordered ${props.loader && "loading"}`}
          >
            <thead className="text-center">
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Model Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.allUpdateCarModel && props.allUpdateCarModel.length > 0 ? (
                props.allUpdateCarModel?.map((model, index) => {
                  return (
                    <tr className="border-bottom" key={index}>
                      <td>{index + 1} </td>
                      <td className="text-center">
                        <img
                          style={{ height: "auto", width: "65px" }}
                          src={`${baseURL}` + model?.image}
                          alt="."
                        />
                      </td>
                      <td>{model?.modelName} </td>
                      <td>
                        <input
                          style={{ display: "none" }}
                          ref={props.inputRef}
                          type="file"
                          onChange={props.profileImagetoBase64}
                        />
                        <button
                          style={{
                            border: "1px solid black",
                            borderRadius: "8px",
                            padding: "8px 10px",
                          }}
                          onClick={() => props.handleClick(model._id)}
                        >
                          choose file...
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
