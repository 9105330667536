import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { baseURL, token } from "../../../utils/constant";
import VenderApprovedByAdminTable from "../../Tables/VenderApprovedByAdminTable";

export default function VenderApprovedByAdmin() {
  const [data, setData] = useState([]);

  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState();
  const [service_Id, setService_Id] = useState();
  const [selecteddata, setSelecteddata] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id, selected) => {
    setService_Id(id);
    setShow(true);
    setSelecteddata(selected);
    getData();
  };

  async function getData() {
    setLoader(true);
    await axios({
      url: `${baseURL}admin/getAllPendingVender`,
      method: "POST",
      headers: {
        authorization: token,
      },
    })
      .then((res) => {
        setLoader(false);
        setData(res.data.Data);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() !== false) {
      await axios({
        url: `${baseURL}vender/UpdateVenderDetails`,
        method: "POST",
        headers: {
          authorization: token,
        },
        data: {
          venderId: service_Id,
          venderStatus: status,
        },
      }).then((res) => {
        getData();
        setShow(false);
      });
    } else {
      event.stopPropagation();
    }

    setValidated(true);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="row">
            <div className="col-12 col-xl-10 mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Pending WC Partner By Admin</h3>
              <b>
                {data ? `${data?.length} WC Partner Approved By Admin` : ``}
              </b>
            </div>
          </div>
        </div>
      </div>

      <VenderApprovedByAdminTable
        loader={loader}
        data={data}
        handleShow={handleShow}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Approve Vender</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Workshop Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Workshop Name"
                  value={selecteddata?.workshopName}
                  required
                  contentEditable={false}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Workshop Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Workshop Address"
                  value={selecteddata?.workshopAddress}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Owner Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="car type"
                  value={selecteddata?.ownerName}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="phone"
                  value={selecteddata?.phone}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>GST Number</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  placeholder="GST Number"
                  value={selecteddata?.gstNumber}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>PanCard Number</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  placeholder="PanCard Number"
                  value={selecteddata?.panCartNumber}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              {selecteddata?.gstImage === "" ? (
                <Form.Group
                  className="col-lg-6"
                  md="3"
                  controlId="validationCustom04"
                ></Form.Group>
              ) : (
                <Form.Group
                  className="col-lg-6"
                  md="3"
                  controlId="validationCustom04"
                >
                  <Form.Label>GST Image</Form.Label>
                  <img src={`${baseURL}` + selecteddata?.gstImage} alt="img"/>
                  <Form.Control.Feedback type="invalid">
                    Please provide a Field.
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              {selecteddata?.panCartImage === "" ? (
                <Form.Group
                  className="col-lg-6"
                  md="3"
                  controlId="validationCustom04"
                ></Form.Group>
              ) : (
                <Form.Group
                  className="col-lg-6"
                  md="3"
                  controlId="validationCustom04"
                >
                  <Form.Label>Pan Cart Image</Form.Label>
                  <img src={`${baseURL}` + selecteddata?.panCartImage} alt="img"/>
                </Form.Group>
              )}
            </Row>

            <Row className="mb-3">
              <Form.Group
                className="col-lg-6"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Select Status</Form.Label>
                <select
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  name="cars"
                  id="cars"
                >
                  <option>Select</option>
                  <option value={2}>verified</option>
                  <option value={3}>rejected</option>
                  <option value={1}>not verified</option>
                </select>
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" className="btn btn-success" variant="success">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
