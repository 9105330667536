import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function ViewMembership(props) {
  const propsMemvberArray = props?.membership?.service;
  const servicesAll = props?.servicesAll;
  const [lgShow, setLgShow] = useState(false);

  const allMapedTitle = [];

  const [memberArray, setMemberArray] = useState([]);

  async function SetMembersArray() {
    setMemberArray(propsMemvberArray);
  }

  useEffect(() => {
    SetMembersArray();
  }, []);

  return (
    <>
      <svg
        onClick={() => setLgShow(true)}
        style={{ cursor: "pointer" }}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#223385"
        className="bi bi-eye-fill"
        viewBox="0 0 16 16"
      >
        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
      </svg>

      <Modal
        show={lgShow}
        onHide={() => setLgShow(false)}
        size="xl"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">View Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Price</th>
                  <th>Total Month</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <td>{props?.membership?.title}</td>
                  <td>{props?.membership?.description}</td>
                  <td>{props?.membership?.price}</td>
                  <td>{props?.membership?.totalMonth}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr />

          <div className="mb-3">
            <h5>Member Service ({memberArray?.length})</h5>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Quantity</th>
                  <th>Discount</th>
                </tr>
              </thead>
              <tbody>
                {memberArray?.map((memSer, index) => {
                  const filtered = servicesAll?.find(
                    (fil) => fil?._id === memSer.serviceId
                  );
                  allMapedTitle.push(filtered?.title);
                  return (
                    <tr className="border-bottom" key={index}>
                      <td className="col-1"> {filtered?.title}</td>
                      <td className="col-1"> {memSer?.qty}</td>
                      <td className="col-1"> {memSer?.discount}%</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
