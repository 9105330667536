import React, { useEffect, useRef, useState } from "react";
import { Button, Collapse, Form, Modal, Row } from "react-bootstrap";
import { baseURL, token } from "../../../utils/constant";
import axios from "axios";
import MembershipTable from ".././../Tables/MembershipTable";

export default function Membership() {
  const formRef = useRef(null);
  const [allMembership, setAllMembership] = useState([]);
  const [loader, setLoader] = useState(false);
  let openMembership = true;
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [qtyOrDiscValues, setQtyOrDiscValues] = useState([]);

  const [submiting, setSubmiting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [services, setServices] = useState([]);
  const valueData = {
    title: "",
    description: "",
    price: "",
    timeTimit: "",
    totalMonth: "",
  };
  const serviceData = [
    {
      serviceId: "",
      serviceName: "",
      qty: "0",
      discount: "0",
    },
  ];
  const [inputValues, setInputValues] = useState(valueData);
  const [inputArray, setInputArray] = useState(serviceData);

  const handleClose = () => {
    setShow(false);
    setInputValues(valueData);
    setInputArray(serviceData);
  };
  async function getAllMembership() {
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);

    const raw = "";

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    setLoader(true);
    await fetch(`${baseURL}admin/getAllMemberShip_v6`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        setAllMembership(result.Data);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  }

  const handleClear = (e) => {
    e.preventDefault();
    setValidated(false);
    setShow(true);
    setIsEdit(false);
  };

  // changeInputValues
  function changeInputValues(evt) {
    const value = evt.target.value;
    setInputValues({
      ...inputValues,
      [evt.target.name]: value,
    });
  }

  // handleAdd
  function handleAdd() {
    setInputArray([
      ...inputArray,
      {
        serviceId: "",
        serviceName: "",
        qty: "0",
        discount: "0",
      },
    ]);
  }

  // handleRemove
  function handleRemove(index) {
    const value = [...inputArray];
    value.splice(index, 1);
    setInputArray(value);
  }

  const handleSubmitMembership = async (event) => {
    setSubmiting(true);
    event.preventDefault();
    let urlPath = "addNewMemberShip";
    const data = {
      title: inputValues?.title,
      description: inputValues?.description,
      price: inputValues?.price,
      timeTimit: inputValues?.timeLimit,
      totalMonth: inputValues?.totalMonth,
      service: inputArray,
    };
    if (isEdit) {
      data.membershipId = isEdit;
      urlPath = "updateMemberShip";
    }
    const config = {
      method: "post",
      url: `${baseURL}admin/${urlPath}`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        getAllMembership();
        setSubmiting(false);
        handleClose();
        window.location.reload();
      })
      .catch(function (error) {
        setSubmiting(false);
        console.log(error);
      });
    setValidated(true);
  };

  async function getAllServiceCategory() {
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);

    const raw = "";

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(`${baseURL}admin/getAllRegulerService`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setServices(result.Data);
      })
      .catch((error) => console.log("error", error));
  }

  // delete
  const deleteMembership = async (id) => {
    const selected = allMembership?.filter((brand) => brand?._id === id);
    var myHeaders = new Headers();
    myHeaders.append("authorization", " ");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      memberShipId: selected[0]?._id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    if (window.confirm("Delete?")) {
      await fetch(`${baseURL}admin/deleteMemberShip`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          getAllMembership();
        })
        .catch((error) => console.log("error", error));
    }
  };

  // handleQtyOrDiscount
  function handleQtyOrDiscount(e, index) {
    const qtyOrDiscArr = [...qtyOrDiscValues];
    qtyOrDiscArr[index] = e.target.value;
    setQtyOrDiscValues(qtyOrDiscArr);
  }

  function handleChangeArray(e, index) {
    const values = [...inputArray];
    if(e.target.name === "discount"){
      values[index]["qty"] = 0;
    }else if(e.target.name === "qty"){
      values[index]["discount"] = 0;
    }
    values[index][e.target.name] = e.target.value;
    setInputArray(values);
  }

  const handleEditItems = (items) => {
    console.log(items);
    setIsEdit(items._id);
    setInputValues({
      ...inputValues,
      title: items?.title,
      description: items?.description,
      price: items?.price,
      timeLimit: items?.timeTimit,
      totalMonth: items?.totalMonth,
    });
    if (items?.service.length > 0) {
      const inputArr = [];
      const qtyDisc = [];
      items?.service.forEach((item) => {
        const inputData = {
          serviceId: item?.serviceId,
          serviceName: item?.serviceName,
          qty: Number(item?.qty),
          discount: item?.discount,
        };
        if (item?.discount > 0) {
          qtyDisc.push("discount");
        } else {
          qtyDisc.push("quantity");
        }
        inputArr.push(inputData);
      });
      setQtyOrDiscValues(qtyDisc);
      setInputArray(inputArr);
    }
    setShow(true);
  };

  let setINdexOfInputArrView;

  useEffect(() => {
    getAllMembership();
    getAllServiceCategory();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Membership</h3>
              <b>
                {allMembership ? `${allMembership?.length} Membership` : ``}
              </b>
            </div>
            <Form
              ref={formRef}
              onSubmit={(e) => {
                handleClear(e);
              }}
              noValidate
              validated={validated}
            >
              <button type="submit" className="btn btn-primary p-2">
                + New Membership
              </button>
            </Form>
          </div>
        </div>
      </div>

      <MembershipTable
        loader={loader}
        allMembership={allMembership}
        deleteMembership={deleteMembership}
        handleEditItems={handleEditItems}
        services={services}
        setINdexOfInputArrView={setINdexOfInputArrView}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form
          noValidate
          validated={validated}
          ref={formRef}
          onSubmit={handleSubmitMembership}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {isEdit ? "Edit Membership" : "Membership"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={inputValues?.title}
                  onChange={changeInputValues}
                  placeholder="title"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={inputValues?.description}
                  onChange={changeInputValues}
                  placeholder="description"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="text"
                  name="price"
                  value={inputValues?.price}
                  onChange={changeInputValues}
                  placeholder="price"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Total Month</Form.Label>
                <Form.Control
                  type="text"
                  name="totalMonth"
                  value={inputValues?.totalMonth}
                  onChange={changeInputValues}
                  placeholder="total month"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Time Limit</Form.Label>
                <Form.Control
                  type="text"
                  name="totalMonth"
                  value={inputValues?.totalMonth}
                  onChange={changeInputValues}
                  placeholder="time limit"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3"></Row>
            <hr />
            <Row>
              <Collapse in={openMembership}>
                <div id="example-collapse-text">
                  <Form.Group
                    className="mb-2"
                    md="3"
                    controlId="validationCustom04"
                  >
                    <div>
                      {inputArray.map((item, index) => {
                        if (Number(item.qty) > 0) item.discount = 0;
                        if (Number(item.qty) < 0) item.discount = 0;
                        if (Number(item.discount) > 0) item.qty = 0;
                        if (Number(item.discount) < 0) item.qty = 0;
                        if (Number(item.discount) > 100) item.discount = 0;
                        item.qty = Number(item.qty);
                        item.discount = Number(item.discount);
                        return (
                          <div key={index} className="mb-2">
                            <Form.Label>Service</Form.Label>
                            <div>
                              <Form.Select
                                name="serviceId"
                                required
                                value={item?.serviceId}
                                onChange={(e) => {
                                  handleChangeArray(e, index);
                                }}
                                aria-label="Default select example"
                              >
                                <option>Select</option>
                                {services?.map((type, index) => {
                                  return (
                                    <option key={index} value={type?._id}>
                                      {type?.title}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <hr />

                              {/* ---------------select one of field dis or qty------------------------------------------------------------------ */}
                              <div style={{ margin: "18px 0 25px 0" }}>
                                <Form.Label>
                                  Quantity <i>or</i> Discount{" "}
                                </Form.Label>
                                <Form.Select
                                  required
                                  value={qtyOrDiscValues[index]}
                                  onChange={(e) =>
                                    handleQtyOrDiscount(e, index)
                                  }
                                  aria-label="Default select example"
                                >
                                  <option>Select</option>
                                  <option value="quantity">Quantity</option>
                                  <option value="discount">Discount</option>
                                </Form.Select>

                                {qtyOrDiscValues[index] === "quantity" && (
                                  <div
                                    style={{
                                      margin: "15px 0 25px 0",
                                      borderBottom: "1px solid #223385",
                                    }}
                                  >
                                    <Form.Label>Quantity </Form.Label>
                                    <Form.Control
                                      style={{ width: "100%" }}
                                      type="number"
                                      name="qty"
                                      value={item?.qty}
                                      // value={item.qty}
                                      onChange={(e) => {
                                        handleChangeArray(e, index);
                                      }}
                                      placeholder="Quantity"
                                      required
                                    />
                                  </div>
                                )}
                                {qtyOrDiscValues[index] === "discount" && (
                                  <div
                                    style={{
                                      margin: "15px 0 25px 0",
                                      borderBottom: "1px solid #223385",
                                    }}
                                  >
                                    <Form.Label>Discount (%) </Form.Label>
                                    <Form.Control
                                      style={{ width: "100%" }}
                                      type="number"
                                      name="discount"
                                      value={item?.discount}
                                      // value={item?.discount}
                                      onChange={(e) => {
                                        handleChangeArray(e, index);
                                      }}
                                      placeholder="Discount"
                                      required
                                    />
                                  </div>
                                )}
                              </div>

                              <Button
                                disabled={inputArray.length === 1 && true}
                                variant="danger"
                                style={{
                                  fontSize: "13px",
                                  width: "100% ",
                                  margin: "2px 0 0 0",
                                  fontWeight: "700",
                                }}
                                onClick={() => handleRemove(index)}
                              >
                                - Remove
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                      <Button
                        style={{
                          width: "100%",
                          backgroundColor: "transparent",
                          color: "#223385",
                          border: "1px dashed #223385",
                          margin: "10px 0 0 0",
                        }}
                        onClick={handleAdd}
                      >
                        + Add Service
                      </Button>
                    </div>
                    <hr />
                  </Form.Group>
                </div>
              </Collapse>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" className="btn-bg-blue" varint="primary">
              {submiting ? "Submiting" : "Submit"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
