import React, { useEffect, useState } from "react";
import { baseURL, token } from "../../../utils/constant";
import ShowVenderTable from "../../Tables/ShowVenderTable";
import axios from "axios";
import { toast } from "react-hot-toast";

export default function ShowVender() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  async function getAllData() {
    var myHeaders = new Headers();
    myHeaders.append("authorization", token);

    setLoader(true);
    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(`${baseURL}admin/getAllVender`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.Data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  }
  useEffect(() => {
    getAllData();
  }, []);

  const deleteVender = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var data = JSON.stringify({
      vendorId: id,
    });

    let headers = {
      method: "DELETE",
      headers: myHeaders,
      body: data,
    };
    if (window.confirm("Delete?")) {
      await fetch(`${baseURL}admin/deleteVender`, headers)
        .then((response) => response.json())
        .then((result) => {
          toast.dismiss();
          toast.success(result.message);
          getAllData();
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="row">
            <div className="col-12 col-xl-10 mb-4 mb-xl-0">
              <h3 className="font-weight-bold">WC Partner</h3>
              <b> {data ? `${data?.length}` : "No"} Data </b>
            </div>
          </div>
        </div>
      </div>

      <ShowVenderTable
        loader={loader}
        data={data}
        deleteVender={deleteVender}
      />
    </>
  );
}
