import React, { useEffect, useState } from "react";
import { baseURL, token } from "../../../utils/constant";
import axios from "axios";
import { Button, Modal, Table } from "react-bootstrap";
import DashboardViewPickup from "./DashboardViewPickup";
import NoDataFound from "../../../utils/NoDataFound";

export default function DashbordInprogressTab(props) {
  const [bookingHistory, setBookingHistory] = useState(
    props?.inProgressData ?? []
  );

  const [loader, setLoader] = useState(false);
  const [inputValue, setInputValue] = useState();

  const [venders, setVenders] = useState([]);
  const [updateVenderAssenWork, setUpdateVenderAssenWork] = useState([]);
  const [vender_id, setVender_id] = useState();
  const [venderWorkId, setVenderWorkId] = useState();
  const [updating, setUpdating] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  function UpdateVenderAssenWork() {
    setUpdating(true);
    axios({
      method: "post",
      data: {
        venderWorkId: venderWorkId,
        venderId: vender_id?._id,
      },
      url: `${baseURL}admin/UpdateVenderAssenWork`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setUpdating(false);
        setShow(false);
        props?.getAllInProgress();
        setUpdateVenderAssenWork(response.data.Data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getDataByServiceStatus() {
    await axios({
      method: "post",
      url: `${baseURL}admin/getAllDataByServiceStatus`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: { serviceStatus: parseInt(inputValue) },
    })
      .then((response) => {
        setLoader(false);
        setBookingHistory(response.data.Data);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  }

  function handleChange(e) {
    setLoader(true);
    setInputValue(e.target.value);
  }

  useEffect(() => {
    inputValue && getDataByServiceStatus();
  }, [inputValue]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <select
            className="form-control-sm mb-2"
            value={inputValue}
            onChange={(e) => handleChange(e)}
          >
            <option>Select</option>
            <option value="2">Job card pending</option>
            <option value="3">Job card created by vendor</option>
            <option value="4">Job card Approved successfully</option>
            <option value="5">In Service</option>
          </select>
          <div className="table-responsive pt-3">
            <table className={`table table-bordered ${loader && "loading"}`}>
              <thead className="text-center">
                <tr>
                  <th>#</th>
                  <th>Customer</th>
                  <th>Mobile</th>
                  <th>Workshop</th>
                  <th>
                    Request <br /> Pickup Date
                  </th>
                  <th>
                    Request <br /> Pickup Time
                  </th>

                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {bookingHistory && bookingHistory.length > 0 ? (
                  bookingHistory?.map((hist, index) => {
                    return (
                      <tr className="border-bottom" key={index}>
                        <td>{index + 1}</td>
                        <td>{hist?.VenderWorkDetails[0]?.name}</td>
                        <td>{hist?.VenderWorkDetails[0]?.phone}</td>
                        <td>{hist?.VenderDetails[0]?.workshopName}</td>
                        <td>{hist?.VenderWorkDetails[0]?.pickupDate}</td>
                        <td>{hist?.VenderWorkDetails[0]?.pickupTime}</td>

                        <td>{hist?.totalPay}</td>
                        <td className="text-center">
                          <DashboardViewPickup
                            workshop_id={hist?._id}
                            workshop={hist}
                            index={index + 1}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoDataFound />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        className="modal-dialog-scrollable"
      >
        <Modal.Header closeButton>
          <Modal.Title>Workshop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Owner Name</th>
                <th>Phone</th>
                <th>Workshop Name</th>
              </tr>
            </thead>
            <tbody>
              {venders ? (
                venders?.map((ven, index) => {
                  return (
                    <tr onClick={() => setVender_id(ven)} key={index}>
                      <label htmlFor={ven?._id} style={{ display: "flex" }}>
                        <input
                          type="radio"
                          id={ven?._id}
                          name="fav_language"
                          value="HTML"
                        ></input>
                        <td style={{ margin: "0 0 0 8px" }}>{index + 1}</td>
                      </label>
                      <td>{ven?.ownerName}</td>
                      <td>{ven?.phone}</td>
                      <td>{ven?.workshopName}</td>
                    </tr>
                  );
                })
              ) : (
                <h1>No data</h1>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            className="btnblue"
            variant="success"
            disabled={updating && "true"}
            onClick={UpdateVenderAssenWork}
          >
            {updating ? "Updating.." : "Update Workshop"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
