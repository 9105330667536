import React, { useEffect, useState } from "react";
import { baseURL, token } from "../../../utils/constant";
import ShowUsersTable from "../../Tables/ShowUsersTable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

export default function ShowUsers() {
  const fileName = "UserProfile";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  async function getAllData() {
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);

    const raw = "";

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoader(true);

    fetch(`${baseURL}users/getAllUserProfile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        setData(result.Data);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  }
  useEffect(() => {
    getAllData();
  }, []);

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const tabledata = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(tabledata, fileName + fileExtension);
  };

  return (
    <>
      <style jsx="true">
        {`
          .excel-button {
            background: #3ca3a7;
            color: #fff;
            font-size: 22px;
          }
          .excel-button:hover {
            text-decoration: underline;
            background: #3ca3a7;
            color: #fff;
          }
        `}
      </style>

      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Show Users</h3>
              <b> {data ? `${data?.length}` : "No"} Data </b>
            </div>
            {data.length !== 0 && (
              <button
                className="excel-button btn border d-flex align-items-center"
                onClick={exportToCSV}
              >
                <i className="mdi mdi-file-excel fs-2"></i>
                <span>Export to CSV</span>
              </button>
            )}
          </div>
        </div>
      </div>

      <ShowUsersTable loader={loader} data={data} />
    </>
  );
}
