import React from "react";
import NoDataFound from "../../utils/NoDataFound";

export default function RazorpayTable(props) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive pt-3">
          <table
            className={`table table-bordered ${props.loader && "loading"}`}
          >
            <thead className="text-center">
              <tr>
                <th>No</th>
                <th>Key</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.allKey && props.allKey.length > 0 ? (
                props.allKey?.map((allKey, index) => {
                  return (
                    <tr className="border-bottom" key={index}>
                      <td className="col-2">{index + 1} </td>
                      <td className="col-4">{allKey?.key} </td>
                      <td className="text-center col-4">
                        <button
                          className="btn btn-primary"
                          onClick={(e) => {
                            props.editKey(allKey._id);
                            props.handleShow();
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
