import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL, token } from "../../../utils/constant";
import { Alert, Button, Modal } from "react-bootstrap";
import DashboardViewPickup from "./DashboardViewPickup";
import NoDataFound from "../../../utils/NoDataFound";

export default function DashbordPickupTab(props) {
  const [bookingHistory, setBookingHistory] = useState(props?.pickUpData ?? []);
  const [inputValue, setInputValue] = useState();
  const [isStatusNull, setIsStatusNull] = useState(false);
  const [loader, setLoader] = useState(false);
  const [venders, setVenders] = useState([]);
  const [updateVenderAssenWork, setUpdateVenderAssenWork] = useState([]);
  const [vender_id, setVender_id] = useState();
  const [venderWorkId, setVenderWorkId] = useState();
  const [updating, setUpdating] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setVenderWorkId(id);
    setShow(true);
    getAllVender();
  };

  function getAllVender() {
    axios({
      method: "post",
      url: `${baseURL}admin/getAllVerifiedVender`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setVenders(response.data.Data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function UpdateVenderAssenWork() {
    setUpdating(true);
    axios({
      method: "post",
      data: {
        venderWorkId: venderWorkId,
        venderId: vender_id?._id,
      },
      url: `${baseURL}admin/UpdateVenderAssenWork`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setUpdating(false);
        setShow(false);
        props?.getAllPickup();
        setUpdateVenderAssenWork(response.data.Data);
        getDataByServiceStatus();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getDataByServiceStatus() {
    await axios({
      method: "post",
      url: `${baseURL}admin/getAllDataByServiceStatus`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: { serviceStatus: parseInt(inputValue) },
    })
      .then((response) => {
        setLoader(false);
        setBookingHistory(response.data.Data);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  }

  function handleChange(e) {
    setLoader(true);
    setInputValue(e.target.value);
  }

  useEffect(() => {
    inputValue && getDataByServiceStatus();
  }, [inputValue]);

  return (
    <>
      <form>
        {isStatusNull && (
          <Alert
            variant="danger"
            style={{ padding: "8px 10px", margin: "10px 0 30px 0" }}
          >
            Please select status !
          </Alert>
        )}
      </form>

      <div className="card">
        <div className="card-body">
          <select
            className="form-control-sm mb-2"
            value={inputValue}
            onChange={(e) => handleChange(e)}
          >
            <option>Select</option>
            <option value="0">Booking Placed</option>
            <option value="1">Driver on the way</option>
          </select>
          <div className="table-responsive pt-3">
            <table className={`table table-bordered ${loader && "loading"}`}>
              <thead className="text-center">
                <tr>
                  <th>#</th>
                  <th>Customer</th>
                  <th>Mobile</th>
                  <th>Workshop</th>
                  <th>
                    Request <br /> Pickup Date
                  </th>
                  <th>
                    Request <br /> Pickup Time
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {bookingHistory && bookingHistory.length > 0 ? (
                  bookingHistory?.map((hist, index) => {
                    return (
                      <tr className="border-bottom" key={index}>
                        <td>{index + 1}</td>
                        <td>{hist?.VenderWorkDetails[0]?.name}</td>
                        <td>{hist?.VenderWorkDetails[0]?.phone}</td>
                        <td>{hist?.VenderDetails[0]?.workshopName}</td>
                        <td>{hist?.VenderWorkDetails[0]?.pickupDate}</td>
                        <td>{hist?.VenderWorkDetails[0]?.pickupTime}</td>
                        <td className="text-center">
                          <button
                            className="btn btn-primary me-3"
                            onClick={() => handleShow(hist?._id)}
                          >
                            Change Workshop
                          </button>
                          <DashboardViewPickup
                            workshop_id={hist?._id}
                            workshop={hist}
                            index={index + 1}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoDataFound />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        className="modal-dialog-scrollable"
      >
        <Modal.Header>
          <Modal.Title>Workshop</Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="text-center">
                <tr>
                  <th>#</th>
                  <th>Owner Name</th>
                  <th>Phone</th>
                  <th>Workshop Name</th>
                </tr>
              </thead>
              <tbody>
                {venders ? (
                  venders?.map((ven, index) => {
                    return (
                      <tr
                        className="border"
                        onClick={() => setVender_id(ven)}
                        key={index}
                      >
                        <label htmlFor={ven._id} style={{ display: "flex" }}>
                          <input
                            type="radio"
                            id={ven._id}
                            name="fav_language"
                            value="HTML"
                          ></input>
                          <td className="border-0">{index + 1}</td>
                        </label>
                        <td>{ven.ownerName}</td>
                        <td>{ven.phone}</td>
                        <td>{ven.workshopName}</td>
                      </tr>
                    );
                  })
                ) : (
                  <h1>No data</h1>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="mb-4" variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            className="btn btn-success mb-4"
            variant="success"
            disabled={updating && "true"}
            onClick={UpdateVenderAssenWork}
          >
            {updating ? "Updating.." : "Update Workshop"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
