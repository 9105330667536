import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { baseURL } from "../../../utils/constant";

export default function ViewUsers(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className="btn btn-primary" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-eye-fill"
          viewBox="0 0 16 16"
        >
          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
        </svg>
      </button>

      {/*  */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Users Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table table-bordered text-center">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Gender</th>
                  <th>DOB</th>
                  <th>Phone Number</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <td>{props.members.name ? props.members.name : "-"}</td>
                  <td>{props.members.email ? props.members.email : "-"}</td>
                  <td>{props.members.gender ? props.members.gender : "-"}</td>
                  <td>{props.members.dob ? props.members.dob : "-"}</td>
                  <td>{props.members.phoneNo ? props.members.phoneNo : "-"}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered text-center">
              <thead>
                <tr>
                  <th>Wallet</th>
                  <th>Image</th>
                  <th>Email Token</th>
                  <th>Refferal Point</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <td>{props.members.wallet ? props.members.wallet : "-"}%</td>
                  <td>
                    <img
                      src={`${baseURL}` + props.members.image}
                      alt="img"
                      className="h-100 w-100"
                      style={{ borderRadius: "0" }}
                    />
                  </td>
                  <td>
                    {props.members.emailToken ? props.members.emailToken : "-"}
                  </td>
                  <td>
                    {props.members.refferalPoint
                      ? props.members.refferalPoint
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Address</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <td>{props.members.address ? props.members.address : "-"}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>FCM</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <td
                    style={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {props.members.fcm ? props.members.fcm : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
