import React from "react";
import { NavLink } from "react-router-dom";

export default function Sidebar(props) {
  return (
    <nav
      className={`sidebar sidebar-offcanvas border-end ${
        props.menuActive ? "active" : ""
      }`}
      id="sidebar"
    >
      <ul className="nav">
        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/dashboard" className="nav-link">
            <span className="menu-title">Dashboard</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/service" className="nav-link">
            <span className="menu-title">Service</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/serviceSubCategory" className="nav-link">
            <span className="menu-title">Service Subcategory</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/SOSEmergency" className="nav-link">
            <span className="menu-title">SOS Emergency</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/banner" className="nav-link">
            <span className="menu-title">Banner</span>
          </NavLink>
        </li>

        <hr />

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/fueltype" className="nav-link">
            <span className="menu-title">Fuel Type</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/cartype" className="nav-link">
            <span className="menu-title">Body Type</span>
          </NavLink>
        </li>

        <hr />

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/carBrand" className="nav-link">
            <span className="menu-title">Add Car Brand</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/carmodel" className="nav-link">
            <span className="menu-title">Add Car Model</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/updateCarModelImage" className="nav-link">
            <span className="menu-title">Update Car Model</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/carmodelfuel" className="nav-link">
            <span className="menu-title">Add Car Model Fuel</span>
          </NavLink>
        </li>

        <hr />

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/discountCoupon" className="nav-link">
            <span className="menu-title">Discount Coupon</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/membership" className="nav-link">
            <span className="menu-title">Membership</span>
          </NavLink>
        </li>

        <hr />

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/showUsers" className="nav-link">
            <span className="menu-title">Show Users</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/showMembers" className="nav-link">
            <span className="menu-title">Show Members</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/showVender" className="nav-link">
            {/* <span className="menu-title">Show Vender</span> */}
            <span className="menu-title">WC Partner</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/venderApprovedByAdmin" className="nav-link">
            {/* <span className="menu-title">Pending Vender By Admin</span> */}
            <span className="menu-title">Pending WC Partner By Admin</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/userJobcartApproved" className="nav-link">
            <span className="menu-title">User Job Card Approved</span>
          </NavLink>
        </li>

        <hr />

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/viewSOSEmergency" className="nav-link">
            <span className="menu-title">View SOS Emergency</span>
          </NavLink>
        </li>

        <li className="nav-items" onClick={props.goToTop}>
          <NavLink to="/razorPay" className="nav-link">
            <span className="menu-title">Razor Pay</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
