import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { baseURL, token } from "../../../utils/constant";
import NoDataFound from "../../../utils/NoDataFound";

function MyVerticallyCenteredModal(props) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [dataArraysubCategoryID, setDataArraysubCategoryID] = useState([]);
  const [
    serviceSubCategoryWithCategories,
    setServiceSubCategoryWithCategories,
  ] = useState([]);
  const [service, setService] = useState({});
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carModels, setCarModels] = useState([]);
  const [carBrands, setCarBrands] = useState([]);
  const [fuelTypes, setFuelTypes] = useState();
  const [carModelArr, setCarModelArr] = useState([]);
  const [fuelTypeId, setFuelTypeId] = useState();
  const [inputValues, setInputValues] = useState({
    categoryId: "",
    carModelId: "",
    carBrandId: "",
  });
  const [editItems, setEditItems] = useState({
    mrp: "",
    discount: "",
    currentMrp: "",
  });
  const carBrandIdChange = inputValues?.carBrandId;

  useEffect(() => {
    getAllCarBrands();
    getAllFuelTypes();
  }, []);

  useEffect(() => {
    if (carBrandIdChange) {
      carBrandIdChange === "All" && changeInputValues("carModelId", "All");
      setServiceSubCategoryWithCategories([]);
      getAllCarModels();
      setFuelTypeId("");
    }
  }, [carBrandIdChange]);

  useEffect(() => {
    if (fuelTypeId) {
      getServiceSubCategoryWithCategoryId();
    }
  }, [fuelTypeId]);

  useEffect(() => {
    if (service?._id) {
      setEditItems({
        ...editItems,
        mrp: service.mrp,
        discount: service.discount,
        currentMrp: service.currentMrp,
      });
    }
  }, [service]);

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleOpenEditModal = () => setShowEditModal(true);

  // getallcartype
  function getAllCarBrands() {
    setIsLoading(true);
    axios({
      method: "post",
      url: `${baseURL}admin/getAllCarBrand`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const carBrands = response?.data?.Data;
        setIsLoading(false);
        setCarBrands(carBrands);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getCarModel = async (data) => {
    const config = {
      method: "post",
      url: `${baseURL}admin/getCarModelByCarBrandId`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setIsLoading(true);
    await axios(config)
      .then(function (response) {
        const carTypes = response?.data?.Data;
        setIsLoading(false);
        if (inputValues?.carBrandId === "All") {
          const modelId = carTypes.map((model) => model._id);
          setCarModelArr((prev) => [...prev, ...modelId]);
        } else {
          setCarModels(carTypes);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      });
  };

  // getallcartype
  function getAllCarModels() {
    if (inputValues?.carBrandId === "All") {
      carBrands.forEach((brand) => {
        const data = {
          carBrandId: brand?._id,
        };
        getCarModel(data);
      });
    } else {
      const data = {
        carBrandId: inputValues?.carBrandId,
      };
      getCarModel(data);
    }
  }

  // getallcartype
  function getAllFuelTypes() {
    setIsLoading(true);
    axios({
      method: "post",
      url: `${baseURL}admin/getAllFuelType`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const fuelType = response?.data?.Data;
        setIsLoading(false);
        setFuelTypes(fuelType);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // changeInputValues
  function changeInputValues(name, value) {
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  }

  // getServiceSubCategoryWithCategoryId
  async function getServiceSubCategoryWithCategoryId() {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("authorization", token);
    myHeaders.append("Content-Type", "application/json");

    let carModelBody = [];
    if (inputValues.carBrandId === "All") {
      carModelBody = carModelArr;
    } else if (inputValues.carModelId === "All") {
      carModelBody = carModels.map((model) => model._id);
    } else {
      carModelBody.push(inputValues?.carModelId);
    }
    var raw = JSON.stringify({
      categoryId: props?.serviceProps?._id,
      carModelId: carModelBody,
      fuelTypeId:
        fuelTypeId === "All" ? fuelTypes.map((fuel) => fuel._id) : [fuelTypeId],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    await fetch(
      `${baseURL}admin/getSubCategoryByCarmodelId_v5`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        var dataArrayID = [];
        result.Data &&
          result.Data.map((item) => {
            return dataArrayID.push(item);
          });
        setDataArraysubCategoryID(dataArrayID);
        const categoryData = result?.Data;
        const key = "regulerServiceId";
        const arrayUniqueByKey = [
          ...new Map(categoryData.map((item) => [item[key], item])).values(),
        ];
        setServiceSubCategoryWithCategories(arrayUniqueByKey);
      })
      .catch((error) => console.log("error", error));

    setIsLoading(false);
  }

  const handleDeleteAPI = async (data) => {
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);
    myHeaders.append("Content-Type", "application/json");

    await axios
      .post(`${baseURL}admin/deleteService_v5`, data, myHeaders)
      .then((result) => {
        getServiceSubCategoryWithCategoryId();
      })
      .catch((error) => console.log("error", error));
  };

  const handleDataCategoryId = (service) => {
    const dataArr = [];
    let data = {};
    if (inputValues?.carModelId === "All") {
      // data.subCategoryId = service.subCategoryId;
      data.regulerServiceId = service.regulerServiceId;
      dataArr.push(data);
    } else {
      if (fuelTypeId === "All" || fuelTypeId === service.carFualTypeId) {
        // if (fuelTypeId !== "All" || fuelTypeId === service.carFualTypeId) {
        data.serviceId = service._id;
        dataArr.push(data);
      } else {
        const filteredFuel = fuelTypes.filter(
          (fuel) => fuel._id === fuelTypeId
        );
        filteredFuel.forEach((fuel) => {
          const serviceData = serviceSubCategoryWithCategories.find(
            (serviceCat) =>
              serviceCat.carFualTypeId === fuel._id &&
              serviceCat.regulerServiceId === service.regulerServiceId &&
              serviceCat.carModelId === service.carModelId
          );
          if (serviceData && serviceData?._id) {
            data.serviceId = service._id;
            dataArr.push(data);
          }
        });
      }
    }
    return dataArr;
  };

  // delete
  const deleteSubcategory = async (category) => {
    if (window.confirm("Delete?")) {
      const deleteData = handleDataCategoryId(category);
      deleteData.forEach((catData) => {
        const raw = catData;
        handleDeleteAPI(raw);
      });
    }
  };

  const handleUpdateAPI = async (data) => {
    await axios({
      // url: `${baseURL}/admin/updateService_v5`,
      url: `${baseURL}admin/updateServiceByDiscount`,
      method: "POST",
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data,
    })
      .then((result) => {
        setValidated(false);
        getServiceSubCategoryWithCategoryId();
      })
      .catch((error) => {
        alert(error?.message);
        console.log("error", error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let dataArrayID = [];
    dataArraysubCategoryID &&
      dataArraysubCategoryID.map((item) => {
        if (service.title === item.title) {
          return dataArrayID.push(item._id);
        }
      });
    const data = {
      // mrp: editItems.mrp,
      discount: editItems.discount,
      // currentMrp: editItems.currentMrp,
      subCategoryId: dataArrayID, // //
    };
    const updateData = handleDataCategoryId(service);
    updateData.forEach((upData) => {
      const newData = { ...data };
      handleUpdateAPI(newData);
    });
  };

  const getCurrentMrp = (mrp, discount) => mrp - mrp * (discount / 100);

  return (
    <>
      {/* View Model */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit subcategory ({service.title}) </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ display: "flex" }} className="col-12">
              <Form.Group
                className="col-lg-6"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Mrp</Form.Label>
                <Form.Control
                  type="number"
                  name="mrp"
                  value={editItems.mrp < 0 ? 0 : editItems.mrp || ""}
                  onChange={(e) => {
                    const currentMrp = getCurrentMrp(
                      e.target.value,
                      editItems?.discount
                    );
                    setEditItems({
                      ...editItems,
                      ["mrp"]: e.target.value,
                      ["currentMrp"]: currentMrp,
                    });
                  }}
                  placeholder="mrp"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <br />
            <Row className="d-flex col-12">
              <Form.Group
                className="col-lg-6"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Discount</Form.Label>
                <Form.Control
                  type="number"
                  name="discount"
                  value={editItems.discount}
                  onChange={(e) => {
                    const currentMrp = getCurrentMrp(
                      editItems?.mrp,
                      e.target.value
                    );
                    setEditItems({
                      ...editItems,
                      ["discount"]: e.target.value,
                      ["currentMrp"]: currentMrp,
                    });
                  }}
                  placeholder="discount"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <br />
            <Row style={{ display: "flex" }} className="col-12">
              <Form.Group
                className="col-lg-6"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Current Mrp</Form.Label>
                <Form.Control
                  type="number"
                  value={Math.round(editItems.currentMrp || "")}
                  placeholder="current mrp"
                  disabled
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
            <Button
              type="submit"
              variant="success"
              onClick={handleCloseEditModal}
            >
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit Model */}
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            View subcategory
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex">
            <Form.Group
              className="col-lg-4"
              md="3"
              controlId="validationCustom04"
            >
              <Form.Label className="m-1">Car Brand</Form.Label>
              <Form.Select
                className="form-control-sm mb-2"
                aria-label="Default select example"
                name="carBrandId"
                value={inputValues?.carBrandId}
                onChange={(e) => {
                  changeInputValues(e.target.name, e.target.value);
                }}
                required
              >
                <option>Select</option>
                <option disabled>
                  {carBrands?.length === 0
                    ? `No car brand`
                    : `Car Brand (${carBrands?.length})`}{" "}
                </option>
                {carBrands?.length > 1 ? <option value="All">All</option> : ""}
                {isLoading ? (
                  <option disabled>Loading..</option>
                ) : (
                  carBrands?.map((brand) => (
                    <option value={brand?._id}>{brand?.brandName}</option>
                  ))
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="col-lg-4"
              md="3"
              controlId="validationCustom04"
            >
              <Form.Label className="m-1">Car Model</Form.Label>
              <Form.Select
                className="form-control-sm mb-2"
                aria-label="Default select example"
                name="carModelId"
                value={inputValues?.carModelId}
                onChange={(e) => {
                  changeInputValues(e.target.name, e.target.value);
                  setFuelTypeId("");
                }}
                required
              >
                <option>Select</option>
                <option disabled>
                  {carModels?.length < 1
                    ? `No car models`
                    : `Car Models (${carModels?.length})`}{" "}
                </option>
                {carModels?.length > 1 ? (
                  <option value="All">All</option>
                ) : inputValues?.carBrandId === "All" ? (
                  <option value="All">All</option>
                ) : (
                  ""
                )}
                {isLoading ? (
                  <option disabled>Loading..</option>
                ) : (
                  carModels?.map((model) => (
                    <option value={model?._id}>{model?.modelName}</option>
                  ))
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="col-lg-4"
              md="3"
              controlId="validationCustom04"
            >
              <Form.Label className="m-1">Fuel Type</Form.Label>
              <Form.Select
                className="form-control-sm mb-2"
                aria-label="Default select example"
                value={fuelTypeId}
                onChange={(e) => setFuelTypeId(e.target.value)}
                required
              >
                <option>Select</option>
                <option disabled>
                  {fuelTypes?.length === 0
                    ? `No fuel type`
                    : `fuel type (${fuelTypes?.length})`}{" "}
                </option>
                {fuelTypes?.length > 1 ? <option value="All">All</option> : ""}
                {isLoading ? (
                  <option disabled>Loading..</option>
                ) : (
                  fuelTypes?.map((fType, index) => {
                    return (
                      <option key={index} value={fType?._id}>
                        {fType?.fuelType}
                      </option>
                    );
                  })
                )}
              </Form.Select>
            </Form.Group>
          </Row>

          <br />
          <br />
          <div className="table-responsive pt-3">
            <table className={`table table-bordered ${isLoading && "loading"}`}>
              <thead className="text-center">
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>MRP</th>
                  <th>Discount</th>
                  <th>Current MRP</th>
                  <th>Services</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {serviceSubCategoryWithCategories &&
                serviceSubCategoryWithCategories.length > 0 ? (
                  serviceSubCategoryWithCategories?.map(
                    (withCategories, index) => {
                      return (
                        <tr className="border-bottom">
                          <td className="align-top">{index + 1}</td>

                          <td className="align-top">{withCategories?.title}</td>

                          <td className="align-top">{withCategories?.mrp}</td>

                          <td className="align-top">
                            {withCategories?.discount}%
                          </td>

                          <td className="align-top">
                            {withCategories?.currentMrp}
                          </td>

                          <td className="align-top">
                            {withCategories?.service?.map((ser, index) => {
                              return (
                                <pre style={{ margin: "0" }}>
                                  {index + 1 + ") " + ser}
                                </pre>
                              );
                            })}
                          </td>
                          <td className="align-top text-center">
                            <button
                              style={{ background: "none", border: "none" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                variant="primary"
                                onClick={() => {
                                  setService(withCategories);
                                  handleOpenEditModal();
                                }}
                                style={{
                                  margin: "0 15px",
                                  cursor: "pointer",
                                }}
                                width="16"
                                height="16"
                                fill="#223385"
                                className="bi bi-pencil-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fillRule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                              <svg
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  deleteSubcategory(withCategories);
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="red"
                                className="bi bi-archive-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z" />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <NoDataFound />
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default function ViewSubCategory(props) {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => setModalShow(true)}
        style={{ cursor: "pointer" }}
        width="20"
        height="20"
        fill="#223385"
        className="bi bi-eye-fill"
        viewBox="0 0 16 16"
      >
        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
      </svg>
      {modalShow && (
        <MyVerticallyCenteredModal
          show={modalShow}
          serviceProps={props?.service}
          onHide={() => setModalShow(false)}
        />
      )}
    </>
  );
}
