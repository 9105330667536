import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { baseURL, token } from "../../../utils/constant";
import axios from "axios";
import ServiceSubCategoryTable from "../../Tables/ServiceSubCategoryTable";

export default function ServiceSubCategory() {
  const [regulerService, setRegulerService] = useState([]);

  const [isEditEvent, setIsEditEvent] = useState(false);

  const [selectedRegServiceId, setSelectedRegServiceId] = useState();

  const [loader, setLoader] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const [serviceTitle, setServiceTitle] = useState("");

  const [inputArray, setInputArray] = useState([]);

  const [iconImage, setIconImage] = useState();
  const [image, setImage] = useState();

  const handleClose = () => {
    setInputArray([]);
    setServiceTitle("");
    setShow(false);
    setIsEditEvent(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  async function getAllServiceCategory() {
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);

    const raw = "";

    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(`${baseURL}admin/getAllRegulerService`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setRegulerService(result.Data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    setValidated(true);
    if (form.checkValidity() !== false) {
      setIsAdding(true);
      if (isEditEvent) {
        const data = {
          regulerServiceId: selectedRegServiceId,
          title: serviceTitle,
          service: inputArray,
          icons: iconImage,
          image: image,
        };

        await fetch(`${baseURL}admin/updateRegulerService_v1`, {
          method: "POST", // or 'PUT'
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            setIsAdding(false);
            setShow(false);
            getAllServiceCategory();
          })
          .catch((error) => {
            setShow(true);
            setIsAdding(false);
            alert(error?.message);
            console.error("Error:", error);
          });
      }

      if (!isEditEvent) {
        if (!form.checkValidity() === false) {
          setIsEditEvent(false);
          setIsAdding(true);

          await axios({
            method: "post",
            url: `${baseURL}admin/addRegulerService`,
            data: {
              title: serviceTitle,
              service: inputArray,
              icons: iconImage,
              image: image,
            },
            headers: {
              authorization: token,
              "Content-Type": "application/json",
            },
          })
            .then(function (response) {
              setIsAdding(false);
              setShow(false);
              getAllServiceCategory();
            })
            .catch(function (error) {
              setIsAdding(false);
              setShow(true);
              console.log("error", error);
              alert(error?.message);
            });
        } else {
          event.stopPropagation();
        }
      }
    } else {
      event.stopPropagation();
    }
  };

  // deleteRegulerService
  const deleteRegulerService = async (id) => {
    const selected = regulerService?.filter((brand) => brand?._id === id);

    var myHeaders = new Headers();
    myHeaders.append("authorization", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      regulerServiceId: selected[0]?._id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    if (window.confirm("Delete?")) {
      await fetch(`${baseURL}admin/deleteRegulerService_v1`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          getAllServiceCategory();
        })
        .catch((error) => console.log("error", error));
    }
  };

  // edit brand
  function editRegulerService(id) {
    handleShow();
    const selectedSubService = regulerService?.filter(
      (brand) => brand?._id === id
    );
    const seleServiceTitle = selectedSubService[0]?.title;
    const seleServiceArr = selectedSubService[0]?.service;

    setIsEditEvent(true);

    setServiceTitle(seleServiceTitle);
    setInputArray(seleServiceArr);

    setSelectedRegServiceId(id);
  }

  // handleChangeArray
  function handleChangeArray(e, index) {
    const values = [...inputArray];
    values[index] = e.target.value;
    setInputArray(values);
  }

  // handleAdd
  function handleAdd() {
    setInputArray([...inputArray, ""]);
  }

  // handleRemove
  function handleRemove(index) {
    const value = [...inputArray];
    value.splice(index, 1);
    setInputArray(value);
  }

  // iconsImagetoBase64
  const iconsImagetoBase64 = (e) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => resolve(setIconImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  };

  // imagetoBase64
  const imagetoBase64 = (e) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => resolve(setImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    getAllServiceCategory();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Service Subcategory</h3>
              <b>
                {regulerService
                  ? `${regulerService?.length} Service Subcategory`
                  : ``}
              </b>
            </div>
            <button className="btn btn-primary p-2" onClick={handleShow}>
              + Service Subcategory
            </button>
          </div>
        </div>
      </div>

      <ServiceSubCategoryTable
        loader={loader}
        regulerService={regulerService}
        deleteRegulerService={deleteRegulerService}
        editRegulerService={editRegulerService}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditEvent ? "Edit Service Subcategory" : `Service Subcategory`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group
                className="col-lg-12"
                md="3"
                controlId="validationCustom05"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={serviceTitle}
                  placeholder="title"
                  onChange={(e) => setServiceTitle(e.target.value)}
                  required={isEditEvent ? false : true}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
                <hr />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group
                className="col-lg-12"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Icon</Form.Label>
                <Form.Control
                  type="file"
                  name="icons"
                  onChange={iconsImagetoBase64}
                  placeholder="icon"
                  required={isEditEvent && false}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group
                className="col-lg-12"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>
                  Image{" "}
                  <span className="figure-caption">
                    ( H = 550px, W = 400px )
                  </span>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={imagetoBase64}
                  name="image"
                  placeholder="image"
                  required={isEditEvent && false}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Field.
                </Form.Control.Feedback>
                <hr />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-12 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Service</Form.Label>
                <div>
                  {inputArray.map((item, index) => {
                    return (
                      <Form.Group
                        className="mb-2"
                        md="3"
                        controlId="validationCustom05"
                        key={index}
                      >
                        <Form.Label>Service {index + 1} </Form.Label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Control
                            type="text"
                            value={item}
                            onChange={(e) => handleChangeArray(e, index)}
                            placeholder="service"
                            required
                          />
                          <Button
                            disabled={inputArray.length === 1 && true}
                            variant="danger"
                            style={{
                              width: "8% ",
                              margin: "0 0 0 6px",
                              fontWeight: "700",
                            }}
                            onClick={() => handleRemove(index)}
                          >
                            -
                          </Button>
                        </div>
                      </Form.Group>
                    );
                  })}
                  <Button
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                      color: "#223385",
                      border: "1px dashed #223385",
                      margin: "10px 0 0 0",
                    }}
                    onClick={handleAdd}
                  >
                    + Add Service
                  </Button>
                </div>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer className="sticky-bottom bg-light">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="success" disabled={isAdding && true}>
              {isAdding ? "Submiting.." : "Submit"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
