import React, { useState } from "react";
import axios from "axios";
import { baseURL, token } from "../../../utils/constant";
import { Modal, Table } from "react-bootstrap";

export default function DashboardViewPickup(props) {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [updating, setUpdating] = useState();
  const [bookingV1, setBookingV1] = useState([]);

  const [carImage, setCarImage] = useState([]);

  const wokshops = props?.workshop;

  function handleShow() {
    axios({
      method: "post",
      data: {
        bookingId: wokshops?.VenderWorkDetails[0]?._id,
      },
      url: `${baseURL}users/getBooking_v1`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setUpdating(false);
        setBookingV1(response.data.Data);
      })
      .catch((error) => {
        console.error(error);
      });
    setShow(true);
    //
    axios({
      method: "post",
      data: {
        bookingId: wokshops?.VenderWorkDetails[0]?._id,
      },
      url: `${baseURL}vender/getCarImage`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setCarImage(response.data.Data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <button className="btn btn-info" onClick={() => handleShow()}>
        View
      </button>

      <Modal
        dialogClassName="m-0"
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br />

          <br />
          <div style={{ fontSize: "13px", width: "85%", margin: "0 auto" }}>
            <h5>
              <b> Service details</b>
            </h5>
          </div>
          <Table
            striped
            hover
            style={{ fontSize: "13px", width: "85%", margin: "0 auto" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Image</th>
                <th>Mrp</th>
                <th>Current Mrp</th>
                <th>Discount</th>
              </tr>
            </thead>
            <tbody>
              {bookingV1 ? (
                bookingV1?.map((book, key) => {
                  return (
                    <tr key={key}>
                      <td>{}</td>
                      <td>{book?.serviceDetails[0]?.title}</td>
                      <td style={{ height: "100px", width: "100px" }}>
                        <img
                          style={{
                            height: "auto",
                            width: "100%",
                            borderRadius: 0,
                          }}
                          src={
                            ("Image :",
                            baseURL + book?.serviceDetails[0]?.image)
                          }
                          alt=""
                        />
                      </td>
                      <td>{book?.serviceDetails[0]?.mrp}</td>
                      <td>{book?.serviceDetails[0]?.currentMrp}</td>
                      <td>{book?.serviceDetails[0]?.discount}</td>
                    </tr>
                  );
                })
              ) : (
                <h4>No data</h4>
              )}
            </tbody>
          </Table>
          <br />
          <Table
            striped
            hover
            style={{ fontSize: "13px", width: "85%", margin: "0 auto" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th></th>
                <th></th>
                <th>Total Pay Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bookingV1 ? (
                bookingV1?.map((book, key) => {
                  return (
                    <tr key={key}>
                      <td>{}</td>
                      <td>{}</td>
                      <td style={{ height: "100px", width: "100px" }}></td>
                      <td>{}</td>
                      <td>{book?.serviceDetails[0]?.currentMrp}</td>
                      <td>{}</td>
                    </tr>
                  );
                })
              ) : (
                <h4>No data</h4>
              )}
            </tbody>
          </Table>

          <div className="container pt-5">
            {carImage?.map((item, key) => {
              return (
                <div className="row g-4" key={key}>
                  <div className="col-lg-2 col-md-6 col-12">
                    <label>Front Image :</label>
                    {item.carImageOne ? (
                      <img
                        className="card-img-top"
                        src={("carImageOne :", baseURL + item.carImageOne)}
                        alt="img"
                        style={{ height: "15rem" }}
                      />
                    ) : (
                      "-"
                    )}
                  </div>

                  <div className="col-lg-2 col-md-6 col-12">
                    <label>Rear Image :</label>
                    {item.carImageTwo ? (
                      <img
                        className="card-img-top"
                        src={("carImageTwo :", baseURL + item.carImageTwo)}
                        alt="img"
                        style={{ height: "15rem" }}
                      />
                    ) : (
                      "-"
                    )}
                  </div>

                  <div className="col-lg-2 col-md-6 col-12">
                    <label>LH Image :</label>
                    {item.carImageThree ? (
                      <img
                        className="card-img-top"
                        src={("carImageThree :", baseURL + item.carImageThree)}
                        alt="img"
                        style={{ height: "15rem" }}
                      />
                    ) : (
                      "-"
                    )}
                  </div>

                  <div className="col-lg-2 col-md-6 col-12">
                    <label>RH Image :</label>
                    {item.carImageFour ? (
                      <img
                        className="card-img-top"
                        src={("carImageFour :", baseURL + item.carImageFour)}
                        alt="img"
                        style={{ height: "15rem" }}
                      />
                    ) : (
                      "-"
                    )}
                  </div>

                  <div className="col-lg-2 col-md-6 col-12">
                    <label>Speedometer Image :</label>
                    {item.carImageFive ? (
                      <img
                        className="card-img-top"
                        src={("carImageFive :", baseURL + item.carImageFive)}
                        alt="img"
                        style={{ height: "15rem" }}
                      />
                    ) : (
                      "-"
                    )}
                  </div>

                  <div className="col-lg-2 col-md-6 col-12">
                    <label>Selfie with car Image :</label>
                    {item.carImageSix ? (
                      <img
                        className="card-img-top"
                        src={("carImageSix :", baseURL + item.carImageSix)}
                        alt="img"
                        style={{ height: "15rem" }}
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
