import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { baseURL, token } from "../utils/constant";
import SOSEmergencyTable from "./Tables/SOSEmergencyTable";
import { toast } from "react-toastify";

export default function SOSEmergency() {
  const [loader, setLoader] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setSOSTitle("");
    setBasePrice("");
    setAdvanceAmmount("");
    setImage("");
    setInputArray([""]);
    setShow(true);
  };
  const [isAdding, setIsAdding] = useState(false);

  const [isEditEvent, setIsEditEvent] = useState(false);

  const [sosData, setSOSData] = useState();

  const [SOSEmergencyId, setSOSEmergencyId] = useState();
  const [inputArray, setInputArray] = useState([""]);
  const [sosTitle, setSOSTitle] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [advancePrice, setAdvanceAmmount] = useState("");
  const [image, setImage] = useState("");

  const [show, setShow] = useState(false);

  async function getAllData() {
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);

    const raw = "";

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoader(true);

    fetch(`${baseURL}admin/getAllSOS`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSOSData(result.Data);
        setLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
      });
  }

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    if (!sosTitle) {
      toast.dismiss();
      toast.error("Enter SOS Title");
      return false;
    }
    if (!basePrice) {
      toast.dismiss();
      toast.error("Enter Base Price");
      return false;
    }
    if (!advancePrice) {
      toast.dismiss();
      toast.error("Enter Advance Price");
      return false;
    }
    if (!image) {
      toast.dismiss();
      toast.error("Select Image");
      return false;
    }
    if (!inputArray) {
      toast.dismiss();
      toast.error("Add Services");
      return false;
    }

    // incusion: inputArray,

    if (isEditEvent) {
      const myHeaders = new Headers();
      myHeaders.append("authorization", token);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        sosId: SOSEmergencyId,
        sosTitle: sosTitle,
        icons: image,
        basePrice: basePrice,
        advancePrice: advancePrice,
        incusion: inputArray,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      setIsAdding(true);

      fetch(`${baseURL}admin/updateSOS`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          getAllData();
          setShow(false);
          setIsAdding(false);
        })
        .catch((error) => {
          setIsAdding(false);
          console.log("error", error);
        });
    }
    if (!isEditEvent) {
      e.preventDefault();
      // alert("Hello");
      const myHeaders = new Headers();
      myHeaders.append("authorization", token);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        sosTitle: sosTitle,
        icons: image,
        basePrice: basePrice,
        advancePrice: advancePrice,
        incusion: inputArray,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      setIsAdding(true);
      fetch(`${baseURL}admin/addNewSOS`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setIsAdding(false);
          setShow(false);
          getAllData();
        })
        .catch((error) => {
          setIsAdding(false);
          console.log("error", error);
        });

      setSOSTitle("");
      setBasePrice("");
      setAdvanceAmmount("");
      // setIncusion("");

      handleClose();
    }
  };

  // delete api call
  function deleteSOSEmergencydata(id) {
    alert("Delete Data");
    const selectSOSEmergency = sosData?.filter(
      (emergency) => emergency?._id === id
    );
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      sosId: selectSOSEmergency[0]._id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseURL}admin/deleteSOS`, requestOptions)
      .then((response) => response.json())
      .then((result) => getAllData())
      .catch((error) => console.log("error", error));
  }

  // edit api call
  function editDiscount(id) {
    handleShow();
    setIsEditEvent(true);

    const selectSOSEmergency = sosData?.filter(
      (emergency) => emergency?._id === id
    );

    const sosTitle = selectSOSEmergency[0]?.sosTitle;
    const basePrice = selectSOSEmergency[0]?.basePrice;
    const advancePrice = selectSOSEmergency[0]?.advancePrice;
    const image = selectSOSEmergency[0]?.icon;
    const incusion = selectSOSEmergency[0]?.incusion;

    setSOSEmergencyId(selectSOSEmergency[0]?._id);

    setSOSTitle(sosTitle);
    setBasePrice(basePrice);
    setAdvanceAmmount(advancePrice);
    setInputArray(incusion);
    setImage(image);
  }

  // image base64

  function profileImagetoBase64(e) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => resolve(setImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  }

  // HANDLE CHANGE ARRAY
  function handleChangeArray(e, index) {
    const values = [...inputArray];
    values[index] = e.target.value;
    setInputArray(values);
  }

  // handleRemove
  function handleRemove(index) {
    const value = [...inputArray];
    value.splice(index, 1);
    setInputArray(value);
  }

  // handleAdd
  function handleAdd() {
    setInputArray([...inputArray, ""]);
  }

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="mb-4 mb-xl-0">
              <h3 className="font-weight-bold">SOS Emergency</h3>
              <b>{sosData ? `${sosData?.length} SOSEmergency` : ``}</b>
            </div>
            <button className="btn btn-primary p-2" onClick={handleShow}>
              Add SOS Emergency
            </button>
          </div>
        </div>
      </div>

      <SOSEmergencyTable
        loader={loader}
        sosData={sosData}
        editDiscount={editDiscount}
        deleteSOSEmergencydata={deleteSOSEmergencydata}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditEvent ? `Edit Discount` : `SOS Emergency`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom05"
              >
                <Form.Label>SOS Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  value={sosTitle}
                  onChange={(e) => setSOSTitle(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Title.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Base Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Base Price"
                  value={basePrice}
                  onChange={(e) => setBasePrice(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Discount.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-12 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Advance Price</Form.Label>
                <Form.Control
                  type="number"
                  value={advancePrice}
                  placeholder="Enter Advance Ammount"
                  onChange={(e) => setAdvanceAmmount(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Description.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>image</Form.Label>
                <Form.Control
                  type="file"
                  // value={icon}
                  placeholder="image"
                  onChange={profileImagetoBase64}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Iamge.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="validationCustom04"
              >
                <Form.Label>Incusion</Form.Label>
                <Form.Control.Feedback type="invalid">
                  Please provide a Ammount.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-lg-12 mb-2"
                md="3"
                controlId="validationCustom04"
              >
                <div>
                  {inputArray.map((item, index) => {
                    return (
                      <div key={index} className="mb-2">
                        <Form.Label>Service {index + 1} </Form.Label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Form.Control
                            style={{ width: "90%" }}
                            type="text"
                            name="fname"
                            value={item.fname}
                            onChange={(e) => handleChangeArray(e, index)}
                            placeholder="service"
                            required
                          />
                          <Button
                            disabled={inputArray.length === 1 && true}
                            variant="danger"
                            style={{
                              width: "8% ",
                              margin: "0 0 0 6px",
                              fontWeight: "700",
                            }}
                            onClick={handleRemove}
                          >
                            -
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                  <Button
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                      color: "#223385",
                      border: "1px dashed #223385",
                      margin: "10px 0 0 0",
                    }}
                    onClick={handleAdd}
                  >
                    + Add Service
                  </Button>
                </div>
                <hr />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="success" disabled={isAdding && true}>
              {isAdding ? "Submiting.." : "Submit"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
