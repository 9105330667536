import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

export default function Header(props) {
  let navigate = useNavigate();

  const logOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("userData");
    localStorage.removeItem("isLogin");
    navigate("/");
  };

  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <NavLink to="/" className="navbar-brand brand-logo fs-2 fw-bold">
          Real Motors
        </NavLink>
        <NavLink to="/" className="navbar-brand brand-logo-mini fs-2 fw-bold">
          RM
        </NavLink>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              data-toggle="dropdown"
              id="profileDropdown"
            >
              <i className="mdi mdi-account-circle fs-1"></i>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              <a className="dropdown-item" onClick={logOut}>
                <i className="mdi mdi-logout text-primary"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
          onClick={props.toggleSidebar}
        >
          <span className="icon-menu"></span>
        </button>
      </div>
    </nav>
  );
}
