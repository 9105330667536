import React from "react";
import NoDataFound from "../../utils/NoDataFound";

export default function ViewSOSEmergencyTable(props) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive pt-3">
          <table
            className={`table table-bordered ${props.loader && "loading"}`}
          >
            <thead className="text-center">
              <tr>
                <th>No</th>
                <th>SOS Title</th>
                <th>User Name</th>
                <th>Mobile No</th>
                <th>Amount</th>
                <th>Advance Price</th>
                <th>Base Price</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.data && props.data.length > 0 ? (
                props.data?.map((sos, index) => {
                  return (
                    <tr className="border-bottom" key={index}>
                      <td className="col-1">{index + 1}</td>
                      <td className="col-2">
                        {sos?.sosDetails[0]?.sosTitle === undefined ||
                        sos?.sosDetails[0]?.sosTitle === null ||
                        sos?.sosDetails[0]?.sosTitle === "" ? (
                          <p className="text-center">-</p>
                        ) : (
                          sos?.sosDetails[0]?.sosTitle
                        )}
                      </td>
                      <td className="col-1">
                        {sos?.userDetails[0]?.name === undefined ||
                        sos?.userDetails[0]?.name === null ||
                        sos?.userDetails[0]?.name === "" ? (
                          <p className="text-center">-</p>
                        ) : (
                          sos?.userDetails[0]?.name
                        )}
                      </td>
                      <td className="col-2">
                        {sos?.phone === undefined ||
                        sos?.phone === null ||
                        sos?.phone === "" ? (
                          <p className="text-center">-</p>
                        ) : (
                          sos?.phone
                        )}
                      </td>
                      <td className="col-1">
                        {sos?.amount === undefined ||
                        sos?.amount === null ||
                        sos?.amount === "" ? (
                          <p className="text-center">-</p>
                        ) : (
                          sos?.amount
                        )}
                      </td>
                      <td className="col-1">
                        {sos?.sosDetails[0]?.advancePrice === undefined ||
                        sos?.sosDetails[0]?.advancePrice === null ||
                        sos?.sosDetails[0]?.advancePrice === "" ? (
                          <p className="text-center">-</p>
                        ) : (
                          sos?.sosDetails[0]?.advancePrice
                        )}
                      </td>
                      <td className="col-1">
                        {sos?.sosDetails[0]?.basePrice === undefined ||
                        sos?.sosDetails[0]?.basePrice === null ||
                        sos?.sosDetails[0]?.basePrice === "" ? (
                          <p className="text-center">-</p>
                        ) : (
                          sos?.sosDetails[0]?.basePrice
                        )}
                      </td>
                      <td className="col-3">
                        {sos?.userDetails[0]?.address === undefined ||
                        sos?.userDetails[0]?.address === null ||
                        sos?.userDetails[0]?.address === "" ? (
                          <p className="text-center">-</p>
                        ) : (
                          sos?.userDetails[0]?.address
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
