import React, { useCallback, useState } from "react";
import { baseURL, token } from "../../../utils/constant";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import axios from "axios";

export default function ApproveJobCart(props) {
  const [modal, setModal] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const toggle = useCallback(() => {
    setModal(!modal);
  });

  // api call
  async function apiCall() {
    setModal(!modal);
    await axios({
      method: "post",
      url: `${baseURL}users/updateUserJobCartApproved`,
      headers: {
        authorization: token,
      },
      data: {
        bookingId: props?.service,
      },
    })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <button className="btn btn-primary" onClick={apiCall}>
        {props.buttonLabel} Approve
      </button>
      <Modal isOpen={modal} toggle={toggle} className={props.className}>
        <ModalHeader toggle={toggle}>Approve Status</ModalHeader>
        <ModalBody>
          <h4>{isApproved ? "job cart approving.." : "Job Cart Approved"}</h4>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-success"
            onClick={toggle}
            disabled={isApproved && "false"}
          >
            Okay
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
