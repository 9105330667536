const development = {
    baseURL: "http://localhost:80/",
    imageBaseURL: "http://localhost:80",
    token: "Mjdjhcbj43jkmsijkmjJKJKJoijlkmlkjo-HfdkvjDJjMoikjnNJn-JNFhukmk"
}
const production = {
    baseURL: "https://api.realmotors.in/",
    imageBaseURL: "https://api.realmotors.in",
    token: "Mjdjhcbj43jkmsijkmjJKJKJoijlkmlkjo-HfdkvjDJjMoikjnNJn-JNFhukmk"
}
export const isProduction = true;
export const baseURL = (isProduction ? production : development).baseURL;
export const token = (isProduction ? production : development).token;