import React from "react";
import NoDataFound from "../../utils/NoDataFound";
import ApproveJobCart from "../Pages/ManageMembers/ApproveJobCart";

export default function UserJobcartApprovedTable(props) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive pt-3">
          <table
            className={`table table-bordered ${props.loader && "loading"}`}
          >
            <thead className="text-center">
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Approve</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.allService && props.allService.length > 0 ? (
                props.allService?.map((service, index) => {
                  return (
                    <tr className="border-bottom" key={index}>
                      <td className="col-1">{index + 1} </td>
                      <td className="col-2">{service?.name} </td>
                      <td className="col-2">{service?.phone} </td>
                      <td className="col-3">{service?.address} </td>
                      <td className="text-center col-2">
                        <ApproveJobCart service={service?._id} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
