import React, { useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { baseURL } from "../../../utils/constant";

export default function ViewVender(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className="btn btn-primary" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-eye-fill"
          viewBox="0 0 16 16"
        >
          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>WorkShop Name</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={props.vender.workshopName}
                  autoFocus
                  readOnly
                />
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Owner Name</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={props.vender.ownerName}
                  autoFocus
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3"></Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Mobile No</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={props.vender.phone}
                  autoFocus
                  readOnly
                />
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>WorkShop Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={props.vender.workshopAddress}
                  autoFocus
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>GST Image</Form.Label>
                <img src={`${baseURL}` + props.vender.gstImage} alt="img"  className="w-100"/>
              </Form.Group>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Pan Card Image</Form.Label>
                <img src={`${baseURL}` + props.vender.panCartImage} alt="img" className="w-100"/>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="col-lg-6 mb-3"
                md="3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Gumastadhara Image</Form.Label>
                <img src={`${baseURL}` + props.vender.gumastadharaImage} alt="img" className="w-100"/>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
