import React, { useEffect, useState } from "react";
import DashboardVendorDetails from "./DashboardVendorDetails";
import DashboardEarningDetails from "./DashboardEarningDetails";
import { Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import { baseURL, token } from "../../../utils/constant";
import DashbordPickupTab from "./DashbordPickupTab";
import DashbordInprogressTab from "./DashbordInprogressTab";
import DashbordCompleteTab from "./DashbordCompleteTab";

export default function Dashboard() {
  const [pickup, setPickup] = useState([]);
  const [inProgress, setInprogress] = useState([]);
  const [complete, setComplete] = useState([]);

  async function getAllPickup() {
    await axios({
      method: "post",
      url: `${baseURL}admin/getBookingHistory`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: { status: 1 },
    })
      .then((response) => {
        setPickup(response.data.Data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getAllInProgress() {
    await axios({
      method: "post",
      url: `${baseURL}admin/getBookingHistory`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: { status: 2 },
    })
      .then((response) => {
        setInprogress(response.data.Data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getAllComplete() {
    await axios({
      method: "post",
      url: `${baseURL}admin/getBookingHistory`,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      data: { status: 3 },
    })
      .then((response) => {
        setComplete(response.data.Data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getAllPickup();
    getAllInProgress();
    getAllComplete();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="row">
            <div className="col-12 col-xl-8 mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Dashboard</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8 col-md-12 col-sm-12 grid-margin transparent mb-0">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 mb-4 stretch-card transparent">
              <div className="card">
                <div className="card-body">
                  <DashboardVendorDetails />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 mb-4 stretch-card transparent">
              <div className="card">
                <div className="card-body">
                  <DashboardEarningDetails />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="mb-5" />

      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-body">
              <Tabs id="fill-tab-example" className="mb-3 border-0">
                <Tab
                  eventKey="pickup"
                  title={`Pickup | ${pickup.length}`}
                  tabClassName="me-1"
                >
                  <DashbordPickupTab
                    pickUpData={pickup}
                    getAllPickup={getAllPickup}
                  />
                </Tab>
                <Tab
                  eventKey="inprog"
                  title={`In Progress | ${inProgress.length}`}
                  tabClassName="me-1"
                >
                  <DashbordInprogressTab
                    inProgressData={inProgress}
                    getAllInProgress={getAllInProgress}
                  />
                </Tab>
                <Tab
                  eventKey="comp"
                  title={`Complete | ${complete.length}`}
                  tabClassName="me-1"
                >
                  <DashbordCompleteTab
                    completeData={complete}
                    getAllComplete={getAllComplete}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
