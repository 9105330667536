import React, { useEffect, useState } from "react";
import { baseURL, token } from "../../../utils/constant";
import ShowMembersTable from "../../Tables/ShowMembersTable";

export default function ShowMembers() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  async function getAllData() {
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);

    const requestOptions = {
      method: "get",
      headers: myHeaders,
    };
    setLoader(true);

    fetch(`${baseURL}admin/getAllMembershipInformation`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        setData(result.Data);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  }
  useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="row">
            <div className="col-12 col-xl-10 mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Show Members</h3>
              <b> {data ? `${data?.length}` : "No"} Data </b>
            </div>
          </div>
        </div>
      </div>

      <ShowMembersTable loader={loader} data={data} />
    </>
  );
}
