import React, { useEffect, useState } from "react";
import { baseURL, token } from "../utils/constant";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import RazorpayTable from "./Tables/RazorpayTable";

export default function Razorpay() {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [allKey, setAllKey] = useState([]);

  async function getAllFuel() {
    setLoader(true);
    await axios({
      url: `${baseURL}admin/getAllRezarpay`,
      method: "POST",
      headers: {
        authorization: token,
      },
    })
      .then((res) => {
        setAllKey(res.data.Data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err--->", err);
      });
  }

  // editKey
  function editKey(id) {
    const selectRazorPay = allKey?.filter((razorpay) => razorpay?._id === id);

    const selectTitle = selectRazorPay[0]?.key;

    setTitle(selectTitle);
    setSelectedId(selectRazorPay[0]?._id);
  }

  function updateKey() {
    const myHeaders = new Headers();
    myHeaders.append("authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      id: selectedId,
      key: title,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseURL}admin/updateRezarpay`, requestOptions)
      .then((response) => response.text())
      .then((result) => getAllFuel())
      .catch((error) => console.log("error", error));

    setTitle("");

    handleClose();
  }

  useEffect(() => {
    getAllFuel();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="row">
            <div className="col-12 col-xl-10 mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Razorpay</h3>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group
            className="col-lg-10"
            md="3"
            controlId="validationCustom04"
          >
            <Form.Label>Key</Form.Label>
            <Form.Control
              type="text"
              value={title}
              placeholder="Enrter Key"
              onChange={(e) => setTitle(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a Field.
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            className="btnblue"
            onClick={(e) => updateKey(e.target.value)}
            variant="success"
          >
            Update Key
          </Button>
        </Modal.Footer>
      </Modal>

      <RazorpayTable
        loader={loader}
        allKey={allKey}
        editKey={editKey}
        handleShow={handleShow}
      />
    </>
  );
}
